import { theme } from "styles/theme";
import { PocketTomStageResult } from "../../../../api/agreementPocket/types";
import { TooltipProps } from "@mui/material/Tooltip/Tooltip";

export interface PocketViewTomCardButtonProps {
  colorVariant: "positive" | "negative";
}

export interface PocketViewTomCardTooltipProps
  extends Omit<TooltipProps, "title" | "onClick">,
    Pick<PocketViewTomCardButtonProps, "colorVariant"> {
  result: PocketTomStageResult;
}

export const tooltipStyle = {
  padding: "16px",
  marginTop: 6,
  backgroundColor: theme.palette.bg.white,
  boxShadow: "0px 24px 48px 0px rgba(16, 24, 40, 0.14)",
};
