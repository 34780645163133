import { Divider, Stack, Tab, Typography } from '@mui/material'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { tomSelector } from '@store/slices/documentsPages/tom'

import { useGetAgreementHistoryListQuery } from '../../../../api/agreementHistory'
import { useGetInnermapTomProceduresQuery } from '../../../../api/internalAgreement'
import { Innermap } from '../../../../api/internalAgreement/types'
import { agreementPhaseByProjectPhase } from '../../../../api/projectAgreement/types'
import InfoIcon from '../../../../assets/icons/InfoIcon'
import Progress from '../../../../components/Progress'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector,
} from '../../../../store/slices/documentsPages/projectInfo'
import {
  setApprovedProcedure,
  setCurrentSchema,
  setIsSelectedProcedure,
} from '../../../../store/slices/internalAgreement'
import { profileSelector } from '../../../../store/slices/profile'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { theme } from '../../../../styles/theme'
import { determineTomType } from '../../../../types/tom'
import { DocViewNavBarContentAgreementAccordion } from '../DocViewNavBarContentAgreementAccordion'
import { DocViewNavBarInternalAgreement } from '../DocViewNavBarInternalAgreement'
import { ScrollableContainer, StyledButtonGroup, StyledWrapper } from './DocViewNavBarContentAgreement.styles'
import { DocViewNavBarContentAgreementProps, TabPanelProps } from './DocViewNavBarContentAgreement.types'

function a11yProps(index: number) {
  return {
    id: `agreement-tab-${index}`,
    'aria-controls': `agreement-tabpanel-${index}`,
  }
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Stack
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`agreement-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Stack>
  )
}

export const DocViewNavBarContentAgreement: FC<DocViewNavBarContentAgreementProps> = ({ initialPocketId }) => {
  const { projectId: projectIdStr, tomId: tomIdStr } = useParams<{ projectId: string; tomId: string }>()
  const projectId = parseInt(projectIdStr!)
  const tomId = parseInt(tomIdStr!)
  const dispatch = useAppDispatch()
  const { tom } = useTypedSelector(tomSelector)
  const isAnnulled = tom?.features.annulment.annulled

  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { userWithLessControls } = useTypedSelector(defaultProjectInfoSelector)
  const {
    company: { userCompanyName },
  } = useTypedSelector(profileSelector)
  const isIrdPhase = selectedProjectPhase === 'Сбор исходных данных'
  const disabledRoadmap = isIrdPhase || userWithLessControls

  const [tabUsed, setTabUsed] = useState<number>(disabledRoadmap ? 2 : 0)

  const { data: innermapTomProcedures, isFetching: isFetchingProcedures } = useGetInnermapTomProceduresQuery({
    projectId,
    tomId,
    type: determineTomType(selectedProjectPhase),
  })

  const { data: companyProcedures = [] } = innermapTomProcedures || {}

  const { data: agreementHistoryData, isFetching: isFetchingAgreementHistory } = useGetAgreementHistoryListQuery({
    projectId,
    tomId,
    type: agreementPhaseByProjectPhase[selectedProjectPhase],
  })

  const { data: historyItems = [] } = agreementHistoryData || {}

  const filteredCompanies = useMemo(() => {
    if (!userWithLessControls) return companyProcedures
    return companyProcedures.filter(({ company }) => company === userCompanyName)
  }, [companyProcedures, userCompanyName, userWithLessControls])

  useEffect(() => {
    if (tabUsed === 0) {
      dispatch(setCurrentSchema({} as Innermap))
      dispatch(setIsSelectedProcedure({ isSelectedProcedure: false }))
    }
    return () => {
      dispatch(setCurrentSchema({} as Innermap))
      dispatch(setIsSelectedProcedure({ isSelectedProcedure: false }))
      dispatch(setApprovedProcedure([]))
    }
  }, [tabUsed])

  return (
    <Stack flex={1}>
      <Stack>
        <StyledButtonGroup
          variant='fullWidth'
          value={tabUsed}
          onChange={(e, newValue) => (disabledRoadmap ? undefined : setTabUsed(newValue))}
        >
          {!disabledRoadmap && <Tab disableRipple {...a11yProps(0)} label='Внешнее' />}
          {!disabledRoadmap && <Divider orientation='vertical' variant='middle' flexItem></Divider>}
          <Tab
            disableRipple
            {...a11yProps(1)}
            label='Внутреннее'
            sx={{ cursor: disabledRoadmap ? 'default' : 'pointer' }}
          />
        </StyledButtonGroup>

        {isAnnulled && (
          <Typography p={'0 20px 16px 0'} variant='body2' color={theme.palette.warning.main} textAlign={'center'}>
            Согласование документа недоступно для тома с признаком “Аннулирован”.
          </Typography>
        )}
      </Stack>
      <ScrollableContainer>
        <CustomTabPanel value={tabUsed} index={0}>
          <Stack spacing={1.5} flex={1}>
            {isFetchingAgreementHistory ? (
              <Progress />
            ) : historyItems.length ? (
              historyItems.map((historyItem) => (
                <DocViewNavBarContentAgreementAccordion
                  item={historyItem}
                  initialPocketId={initialPocketId}
                  key={`${historyItem.roadmap.id}-${historyItem.pocket.id}-${historyItem.tom.id}`}
                />
              ))
            ) : (
              <StyledWrapper direction='row' spacing={0.5} color={theme.palette.secondary.gray}>
                <InfoIcon fontSize='small' />
                <Typography fontSize={14} letterSpacing={0} color='inherit'>
                  Нет истории согласования
                </Typography>
              </StyledWrapper>
            )}
          </Stack>
        </CustomTabPanel>
        <CustomTabPanel value={tabUsed} index={2}>
          <Stack spacing={1.5} flex={1}>
            {filteredCompanies.length ? (
              <DocViewNavBarInternalAgreement companyProcedures={filteredCompanies} />
            ) : (
              <StyledWrapper direction='row' spacing={0.5} color={theme.palette.secondary.gray}>
                <InfoIcon fontSize='small' />
                <Typography fontSize={14} letterSpacing={0} color='inherit'>
                  Нет истории согласования
                </Typography>
              </StyledWrapper>
            )}
          </Stack>
        </CustomTabPanel>
      </ScrollableContainer>
    </Stack>
  )
}
