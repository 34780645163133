import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CheckIcon from '@mui/icons-material/Check'
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import EventNoteIcon from '@mui/icons-material/EventNote'
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff'
import TimelapseIcon from '@mui/icons-material/Timelapse'
import { Button as MuiButton, Stack, Typography } from '@mui/material'
import { compareAsc, startOfDay } from 'date-fns'
import { useSnackbar } from 'notistack'
import { ChangeEvent, FC, ReactElement, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useSendToProdChangeRdMutation } from '@api/rdPhase'

import PersonWorkerIcon from '@assets/icons/PersonWorkerIcon'

import Button from '@components/Button'
import { OverflowTextNew } from '@components/OverflowTextNew'

import useConfirmDialog, { UseExitConfirmProps } from '@hooks/useConfirmDialog'
import useQuery from '@hooks/useQuery'

import {
  TChangeStatus,
  TOM_CHANGE_DEFAULT_STATUS,
  TomInProdStatusWrapper,
  useDeleteTom,
  useDocsSubmit,
} from '@pages/Docs'

import { Innermap } from '../../../../api/internalAgreement/types'
import InfoRow from '../../../../components/InfoRow'
import { InfoRowData } from '../../../../components/InfoRow/InfoRow.types'
import { RegularStackWrapper, TitledWrapper } from '../../../../components/TitledWrapper'
import { StyledSelectMenuItem } from '../../../../components/UserManagement/components/UserRoleSelection/styles'
import { setOpenedDrawer } from '../../../../store/slices/documentsPages/drawerInfo'
import {
  defaultProjectInfoSelector,
  isExtraAccessArchitectorSelector,
  selectedProjectPhaseSelector,
} from '../../../../store/slices/documentsPages/projectInfo'
import {
  currentDocumentSelector,
  docsSelector,
  roadmapAccess,
  setCurrentChange,
  setCurrentDocument,
  tomSelector,
} from '../../../../store/slices/documentsPages/tom'
import { setCurrentSchema, setIsOpenFormAddMember, setSchemaFormMode } from '../../../../store/slices/internalAgreement'
import { profileSelector } from '../../../../store/slices/profile'
// @ts-ignore:next-line
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { ScrollableContainer } from '../../../../styles/global/ScrollableContainer'
import { theme } from '../../../../styles/theme'
import { TomStatus, isIrdTom, isRdTom, tomStatusesArray } from '../../../../types/tom'
import { formatDateToString, formatToDate } from '../../../../utils/formatDate'
import { DocRowData } from '../DocViewNavBarContent/DocViewNavBarContent.types'
import { ChangeSign } from './components/ChangeSign'
import { tomRoleAccess } from './components/ChangeSign/ChangeSign.types'
import { TeminateTomInfo } from './components/TeminateTomInfo'
import { TerminateTomDialog } from './components/TerminateTomDialog'
import { useDownloadFiles } from './hooks/useDownloadFiles'
import { useEditTom } from './hooks/useEditTom'
import { useGetRowsData } from './hooks/useGetRowsData'
import {
  DateWrapper,
  DescriptionWrapper,
  DocViewStatusSelectFormControl,
  EditButton,
  StatusSelectField,
  StatusWrapper,
  TitleWrapper,
} from './styles'

interface ISendToProdChangeRdConfirmParams {
  tomId: number
  changeNum: number
}

type TTomConfirmDialog = 'sendChangeToProd' | 'deleteTom'

const DocViewNavBarContentInfo: FC = () => {
  const dispatch = useAppDispatch()

  const { role } = useTypedSelector(profileSelector)
  const isExtraAccessArchitector = useTypedSelector(isExtraAccessArchitectorSelector)
  const { userWithLessControls, project } = useTypedSelector(defaultProjectInfoSelector)
  const { currentDocument } = useTypedSelector(currentDocumentSelector)
  const { tomChanges } = useTypedSelector(docsSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { tom } = useTypedSelector(tomSelector)
  const isAnnulled = tom?.features.annulment.annulled
  const accessForRoadmapAgreement = useTypedSelector(roadmapAccess)
  const accessToUpdateChange: boolean = tomRoleAccess.includes(role)
  const terminateTomButtonAccess: boolean = accessToUpdateChange
  const [isTerminateDialogOpened, setIsTerminateDialogOpened] = useState<boolean>(false)
  const currentChange = tomChanges.find((item) => item.doc.docId === currentDocument.docId) || null
  const queryHandler = useQuery()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const { title, description, status, plannedDate, forecastDate, actualDate } = tom || {}

  const showSendToProd = (role === 'admin' || role === 'client' || isExtraAccessArchitector) && isRdTom(tom!)

  const showTomSendToProd = showSendToProd && tom.status === 'Согласовано'

  const showChangeSendToProd: boolean = showSendToProd && currentChange?.change.status === 'Согласовано'

  const { inProd: tomInProd, prodDate } = (tom && isRdTom(tom) && tom) || {}
  const { issueDate, durationDate } = (tom && isIrdTom(tom) && tom) || {}

  const onFormPocketClick = () => {
    dispatch(setOpenedDrawer({ openedDrawer: 'formPocket' }))
  }

  const onInternalAgreementClick = () => {
    dispatch(setCurrentSchema({} as Innermap))
    dispatch(setSchemaFormMode({ schemaFormMode: 'view' }))
    dispatch(setIsOpenFormAddMember({ isOpenFormAddMember: { isOpen: false, formMode: 'create' } }))
    dispatch(setOpenedDrawer({ openedDrawer: 'internalAgreement' }))
  }

  const onDocumentVisibilityClick = () => {
    dispatch(setOpenedDrawer({ openedDrawer: 'docAccess' }))
  }

  useEffect(() => {
    const changeId: string | null = queryHandler.get('change')
    if (changeId) {
      const document = tomChanges.find((c) => c.doc.docId === Number(changeId))
      if (document) {
        dispatch(setCurrentDocument({ currentDocument: document.doc }))
        queryHandler.remove('change')
      }
    }
  }, [dispatch, queryHandler, tomChanges])

  const { onDownloadTomFilesClick } = useDownloadFiles()
  const { rowsData } = useGetRowsData()

  const [isShowAgreementButton, setIsShowAgreementButton] = useState<boolean>(true)

  const plannedDateExpired =
    status !== 'Согласовано' && !forecastDate && compareAsc(formatToDate(plannedDate)!, startOfDay(new Date())) === -1
  const forecastDateExpired =
    status !== 'Согласовано' && !!forecastDate && compareAsc(formatToDate(forecastDate)!, startOfDay(new Date())) === -1
  const durationDateExpired = !!durationDate && compareAsc(formatToDate(durationDate)!, startOfDay(new Date())) === -1

  const rowsDateData: InfoRowData[] =
    status === 'Не согласуется'
      ? [
          {
            icon: EventNoteIcon,
            title: 'Дата выдачи',
            value: issueDate || '—',
            iconProps: {
              fontSize: 'small',
            },
            status: true,
            statusColor: !issueDate ? 'empty' : 'secondary',
          },
          {
            icon: EventAvailableIcon,
            title: 'Срок действия',
            value: durationDate || '—',
            iconProps: {
              fontSize: 'small',
            },
            status: true,
            statusColor: !durationDate ? 'empty' : durationDateExpired ? 'error' : 'secondary',
          },
        ]
      : [
          {
            icon: EventNoteIcon,
            title: 'Плановая',
            value: plannedDate || '—',
            iconProps: {
              fontSize: 'small',
            },
            status: true,
            statusColor: !plannedDate ? 'empty' : plannedDateExpired ? 'error' : 'secondary',
          },
          {
            icon: EventNoteIcon,
            title: 'Прогнозная',
            value: forecastDate || '—',
            iconProps: {
              fontSize: 'small',
            },
            status: true,
            statusColor: !forecastDate ? 'empty' : forecastDateExpired ? 'error' : 'secondary',
          },
          ...(status === 'Согласовано'
            ? ([
                {
                  icon: EventAvailableIcon,
                  title: 'Согласования',
                  value: actualDate || '—',
                  iconProps: {
                    fontSize: 'small',
                  },
                  status: true,
                  statusColor: actualDate ? 'secondary' : 'empty',
                },
              ] as InfoRowData[])
            : ([] as InfoRowData[])),
          ...(tomInProd
            ? ([
                {
                  icon: EventAvailableIcon,
                  title: 'Передано в производство',
                  value: prodDate || '—',
                  iconProps: {
                    fontSize: 'small',
                  },
                  status: true,
                  statusColor: prodDate ? 'secondary' : 'empty',
                },
              ] as InfoRowData[])
            : ([] as InfoRowData[])),
        ]

  const changeDates: InfoRowData[] =
    currentChange?.change.status !== 'Не согласуется'
      ? [
          {
            icon: EventNoteIcon,
            title: 'Плановая дата',
            value: currentChange?.change.plannedDate || '—',
            iconProps: {
              fontSize: 'small',
            },
            status: true,
            statusColor: !issueDate ? 'empty' : 'secondary',
          },
          {
            icon: EventNoteIcon,
            title: 'Фактическая дата',
            value: currentChange?.change.actualDate || '—',
            iconProps: {
              fontSize: 'small',
            },
            status: true,
            statusColor: !issueDate ? 'empty' : 'secondary',
            hidden: !currentChange?.change.actualDate,
          },
          {
            icon: EventNoteIcon,
            title: 'В производстве',
            value: currentChange?.change.prodDate || '—',
            iconProps: {
              fontSize: 'small',
            },
            status: true,
            statusColor: !issueDate ? 'empty' : 'secondary',
            hidden: !currentChange?.change.prodDate,
          },
        ]
      : [
          {
            icon: EventNoteIcon,
            title: 'Дата выдачи',
            value: currentChange?.change.issueDate || '—',
            iconProps: {
              fontSize: 'small',
            },
            status: true,
            statusColor: !issueDate ? 'empty' : 'secondary',
          },
          {
            icon: EventNoteIcon,
            title: 'Срок действия',
            value: currentChange?.change.durationDate || '—',
            iconProps: {
              fontSize: 'small',
            },
            status: true,
            statusColor: !issueDate ? 'empty' : 'secondary',
          },
        ]

  const onDeleteSuccess = useCallback(() => {
    enqueueSnackbar('Том успешно удален.', { variant: 'success' })
    navigate('./toms')
  }, [navigate])

  const { onStatusChange, onTomSendToProdClick, ConfirmDialog: StatusConfirmDialog } = useEditTom()
  const { onUpdateChange } = useDocsSubmit()
  const { onDeleteSubmit } = useDeleteTom({ onDeleteSuccess, onDeleteError: () => {} })
  const [sendToProdChangeRd] = useSendToProdChangeRdMutation()

  const onStatusChangeClick = (e: ChangeEvent<HTMLInputElement>) => {
    if (!currentChange) onStatusChange(e)
    else {
      const { change, doc } = currentChange
      const { inProd, ...localChange } = change

      const prevStatus: TChangeStatus | null = currentChange.change.status
      const nextStatus: TChangeStatus | null = (e.target.value as TChangeStatus) || null

      if (nextStatus === 'Согласовано') {
        localChange.actualDate = formatDateToString(new Date(), 'dd.MM.yyyy') || null
      } else {
        localChange.actualDate = null
        localChange.prodDate = null
      }

      if (nextStatus === 'Не согласуется') {
        localChange.plannedDate = null
      } else if (prevStatus === 'Не согласуется') {
        localChange.durationDate = null
        localChange.issueDate = null
      }

      localChange.status = nextStatus

      onUpdateChange({
        changeNum: doc.change,
        change: { ...localChange, change: doc.change },
        files: null,
      })
    }
  }

  const onSendToProdChangeRdConfirm = useCallback((confirm: boolean, params: ISendToProdChangeRdConfirmParams) => {
    const { tomId, changeNum } = params

    if (confirm) {
      sendToProdChangeRd({ id: tomId, change: changeNum })
    }
  }, [])

  const handleDeleteTomConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      onDeleteSubmit()
    }
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<TTomConfirmDialog>('sendChangeToProd')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    sendChangeToProd: {
      handleConfirm: onSendToProdChangeRdConfirm,
      title: 'Передать изменение в производство?',
      body: `Документ "Изменение №${currentChange?.doc.change}" будет передан подрядчику для производства работ.`,
      denyButtonText: 'Нет',
    },
    deleteTom: {
      handleConfirm: handleDeleteTomConfirm,
      title: 'Удалить том?',
      body: `Том будет удален без возможности восстановить.`,
      confirmButtonText: 'Удалить',
      confirmButtonColor: 'error',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const onOpenConfirmDialog = (dialogType: TTomConfirmDialog, dialogProps?: any) => {
    setConfirmDialogTrigger(dialogType)
    openConfirm(dialogProps)
  }

  const onSendToProdClick = () => {
    if (!tom) return

    currentChange
      ? onOpenConfirmDialog('sendChangeToProd', { tomId: tom.id, changeNum: currentChange.doc.change })
      : onTomSendToProdClick(tom.id, title!)
  }

  const renderSelectValueIcon: Record<TomStatus, ReactElement> = {
    'Не согласуется': <DoDisturbAltIcon fontSize='small' color='disabled' />,
    Согласовано: <CheckIcon fontSize='small' color='success' />,
    Корректировка: <HistoryToggleOffIcon fontSize='small' color='error' />,
    'На согласовании': <AccessTimeIcon fontSize='small' color='warning' />,
    Разработка: <TimelapseIcon fontSize='small' color='primary' />,
    'Не в работе': <DoDisturbAltIcon fontSize='small' color='disabled' />,
  }

  const showTomStatusSelect = role === 'admin' || (status !== 'Согласовано' && !userWithLessControls)

  const showChangeStatusSelect =
    role === 'admin' ||
    (currentChange?.change.status !== 'Согласовано' &&
      currentChange?.change.status !== 'Не согласуется' &&
      !userWithLessControls)

  const handleShowAgreementButtonClick = () => {
    !accessForRoadmapAgreement ? onInternalAgreementClick() : setIsShowAgreementButton(false)
  }

  const onEditChangeClick = () => {
    dispatch(setCurrentChange({ change: currentDocument.change }))
    dispatch(setOpenedDrawer({ openedDrawer: 'docChange' }))
  }

  const downloadAllDocsButton = !userWithLessControls && (
    <MuiButton onClick={onDownloadTomFilesClick} size='thin' color='secondary'>
      <Typography variant='buttonSmall' fontWeight={400} fontSize={14} letterSpacing={0.17} textTransform='initial'>
        Скачать все актуальные документы
      </Typography>
    </MuiButton>
  )

  const rowItem = (row: DocRowData) => {
    return (
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        key={row.title}
        sx={{ height: 30 }}
        spacing={1}
      >
        <Stack direction='row' alignItems='center' spacing={1}>
          <row.icon fontSize='small' color='secondary' />
          <Typography variant='body2' component='span'>
            {row.title}:
          </Typography>
        </Stack>

        <OverflowTextNew rows={2} variant='body2' color={theme.palette.text.dark}>
          {row.value || '—'}
        </OverflowTextNew>
      </Stack>
    )
  }

  return (
    <ScrollableContainer spacing={2.5}>
      {isAnnulled && <TeminateTomInfo data={tom.features.annulment} />}

      {!isAnnulled &&
        (currentChange ? showChangeSendToProd : showTomSendToProd) &&
        ((currentChange ? currentChange.change.inProd : tomInProd) ? (
          <TomInProdStatusWrapper direction='row' justifyContent='center' alignItems='center' spacing={1} flex={1}>
            <Typography variant='buttonSmall'>
              {currentChange ? 'Изм. передано в производство' : 'Передано в производство'}
            </Typography>
            <CheckIcon fontSize='small' color='success' />
          </TomInProdStatusWrapper>
        ) : (
          <MuiButton
            onClick={onSendToProdClick}
            variant='outlined'
            size='small'
            color='secondary'
            endIcon={<ArrowForwardIcon fontSize='small' />}
            fullWidth
          >
            {currentChange ? 'Передать изм. в производство' : 'Передать в производство'}
          </MuiButton>
        ))}
      <Stack spacing={3}>
        <TitleWrapper
          title='Шифр документа'
          variant='primary'
          contentWrapper='box'
          bordered='border'
          endAdornment={
            !userWithLessControls &&
            !isAnnulled && (
              <EditButton
                onClick={() => dispatch(setOpenedDrawer({ openedDrawer: 'update' }))}
                variant='text'
                disableRipple
              >
                редактировать
              </EditButton>
            )
          }
        >
          <Typography
            variant='subtitle1'
            fontWeight={500}
            fontSize={14}
            lineHeight='100%'
            sx={{ overflowWrap: 'break-word' }}
          >
            {title}
          </Typography>
        </TitleWrapper>

        <DescriptionWrapper title='Описание документа' variant='primary' contentWrapper='box' bordered='border'>
          <Typography
            variant='body2'
            sx={{ overflowWrap: 'break-word' }}
            color={theme.palette.text.dark}
            component={ScrollableContainer}
          >
            {description}
          </Typography>
        </DescriptionWrapper>

        <Stack spacing={1}>
          {currentChange && (
            <DescriptionWrapper
              title='Номер изменения'
              endAdornment={
                accessToUpdateChange && !isAnnulled ? (
                  <EditButton onClick={() => onEditChangeClick()} variant='text' disableRipple>
                    редактировать
                  </EditButton>
                ) : null
              }
              variant='primary'
              contentWrapper='box'
              bordered='border'
            >
              <Typography
                variant='body2'
                sx={{ overflowWrap: 'break-word' }}
                color={theme.palette.text.dark}
                component={ScrollableContainer}
              >
                {currentDocument.change}
              </Typography>
            </DescriptionWrapper>
          )}

          <StatusWrapper
            title={currentChange ? 'Статус изменения' : 'Статус документа'}
            variant='primary'
            contentWrapper={'none'}
          >
            {!isAnnulled && (currentChange ? showChangeStatusSelect : showTomStatusSelect) ? (
              <DocViewStatusSelectFormControl>
                <StatusSelectField
                  select
                  value={currentChange ? currentChange.change.status || TOM_CHANGE_DEFAULT_STATUS : status}
                  onChange={onStatusChangeClick}
                  SelectProps={{
                    startAdornment:
                      renderSelectValueIcon[
                        currentChange ? currentChange.change.status || TOM_CHANGE_DEFAULT_STATUS : status!
                      ],
                    renderValue: () => {
                      return (
                        <Typography variant='body2' color={theme.palette.text.dark} pt={0.25}>
                          {currentChange ? currentChange.change.status || TOM_CHANGE_DEFAULT_STATUS : status}
                        </Typography>
                      )
                    },
                  }}
                >
                  {tomStatusesArray
                    .filter((status) => {
                      if (status === 'Согласовано')
                        return role === 'admin' || role === 'client' || isExtraAccessArchitector
                      if (status === 'Не согласуется')
                        return (
                          selectedProjectPhase === 'Сбор исходных данных' &&
                          (role === 'admin' || role === 'architector' || role === 'client')
                        )
                      return true
                    })
                    .map((status) => (
                      <StyledSelectMenuItem style={{ maxWidth: '100%' }} value={status} key={status}>
                        {status}
                      </StyledSelectMenuItem>
                    ))}
                </StatusSelectField>
              </DocViewStatusSelectFormControl>
            ) : (
              <RegularStackWrapper bordered={'border'} direction='row'>
                {renderSelectValueIcon[currentChange ? currentChange?.change.status : status!]}
                <Typography variant='body2' color={theme.palette.text.dark}>
                  {currentChange ? currentChange.change.status || TOM_CHANGE_DEFAULT_STATUS : status}
                </Typography>
              </RegularStackWrapper>
            )}

            {!currentChange &&
              !isAnnulled &&
              (isShowAgreementButton ? (
                <MuiButton onClick={handleShowAgreementButtonClick} size='thin' color='secondary'>
                  <Typography
                    variant='buttonSmall'
                    fontWeight={400}
                    fontSize={14}
                    letterSpacing={0.17}
                    textTransform='initial'
                  >
                    Запустить процедуру согласования
                  </Typography>
                </MuiButton>
              ) : (
                <Stack direction='row' spacing={1}>
                  <MuiButton fullWidth onClick={onInternalAgreementClick} size='thin' color='secondary'>
                    <Typography
                      variant='buttonSmall'
                      fontWeight={400}
                      fontSize={14}
                      letterSpacing={0.17}
                      textTransform='initial'
                    >
                      Внутренняя
                    </Typography>
                  </MuiButton>

                  <MuiButton fullWidth onClick={onFormPocketClick} size='thin' color='secondary'>
                    <Typography
                      variant='buttonSmall'
                      fontWeight={400}
                      fontSize={14}
                      letterSpacing={0.17}
                      textTransform='initial'
                    >
                      Внешняя
                    </Typography>
                  </MuiButton>
                </Stack>
              ))}

            {status === 'Согласовано' && !isAnnulled && !currentChange && <ChangeSign />}
          </StatusWrapper>
        </Stack>

        {currentChange ? (
          <>
            <TitledWrapper title='Информация об изменении' variant='primary' contentWrapper='stack' bordered='border'>
              {rowItem({
                icon: PersonWorkerIcon,
                title: 'Проектировщик:',
                value: currentChange.change.architector?.name,
              })}
              {downloadAllDocsButton}
            </TitledWrapper>
            <DateWrapper
              title={currentChange.change.status === 'Не согласуется' ? 'Даты' : 'Даты согласования'}
              variant='primary'
              contentWrapper='stack'
              bordered='border'
            >
              {changeDates.map((row) => !row?.hidden && <InfoRow rowData={row} key={row.title} />)}
            </DateWrapper>
          </>
        ) : (
          <>
            <TitledWrapper
              title='Информация о документе'
              variant='primary'
              contentWrapper='stack'
              bordered='border'
              /* endAdornment={
                !userWithLessControls && (
                  <EditButton onClick={onDocumentVisibilityClick} variant='text' disableRipple>
                    видимость
                  </EditButton>
                )
              } */
            >
              {rowsData.map((row) => rowItem(row))}

              {downloadAllDocsButton}
            </TitledWrapper>
            <DateWrapper title='Даты согласования' variant='primary' contentWrapper='stack' bordered='border'>
              {rowsDateData.map((row) => !row?.hidden && <InfoRow rowData={row} key={row.title} />)}
            </DateWrapper>
          </>
        )}
        {terminateTomButtonAccess && !isAnnulled && (
          <Button
            onClick={() => setIsTerminateDialogOpened(true)}
            style={{ maxWidth: '100%' }}
            bgColor={'#FFB1B126'}
            hoverBgColor={theme.palette.bg.gray}
          >
            <Typography variant='body1' color={theme.palette.error.main}>
              Аннулировать том
            </Typography>
          </Button>
        )}

        {isAnnulled && !userWithLessControls && (
          <Button
            onClick={() => onOpenConfirmDialog('deleteTom')}
            style={{ maxWidth: '100%' }}
            bgColor={'#FFB1B126'}
            hoverBgColor={theme.palette.bg.gray}
          >
            <Typography variant='body1' color={theme.palette.error.main}>
              Удалить том
            </Typography>
          </Button>
        )}
      </Stack>

      <TerminateTomDialog
        isOpen={isTerminateDialogOpened}
        setDialogOpened={setIsTerminateDialogOpened}
        rootShifrName={tom?.title || ''}
      />
      <ConfirmDialog />
      <StatusConfirmDialog />
    </ScrollableContainer>
  )
}

export default DocViewNavBarContentInfo