import { MoreVert as MenuIcon } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import { Box, Stack } from '@mui/material'
import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { Menu } from '@components/Menu'
import { IMenuData } from '@components/Menu/Menu.types'
import { ViewModeButtonGroup } from '@components/ViewModeButtonGroup'

import { DocsTopLegendStyledButton } from '.'
import {
  availableRdsItemsSelector,
  clearFilter,
  filterDataSelector,
} from '../../../../store/slices/documentsPages/filter'
import { defaultProjectInfoSelector } from '../../../../store/slices/documentsPages/projectInfo'
import { profileSelector } from '../../../../store/slices/profile'
import { PagesViewMode, ViewMode, docsViewSelector, setPageViewMode } from '../../../../store/slices/ui'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { DocsTopLegendProps } from './DocsTopLegend.types'

const btnManagementTxt: string = 'Управление документацией'

export const DocsTopLegend: React.FC<DocsTopLegendProps> = ({
  onAddClick,
  onUploadExcelClick,
  onUploadFilesClick,
  onExportArchiveOpenClick,
  showSendNotificationsButton,
  onSendNotificationsOpenClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const dispatch = useAppDispatch()

  const { userWithLessControls } = useTypedSelector(defaultProjectInfoSelector)
  const { filterData } = useTypedSelector(filterDataSelector)
  const { availableRdsItems } = useTypedSelector(availableRdsItemsSelector)
  const viewMode = useTypedSelector(docsViewSelector)

  const { selectedRdsIds } = filterData

  const onViewChange = useCallback((updatedViewMode: ViewMode) => {
    if (updatedViewMode !== null) {
      dispatch(setPageViewMode({ page: 'docs', viewMode: updatedViewMode }))
    }
  }, [])

  const onCloseManagementMenu = () => {
    setAnchorEl(null)
  }
  const onOpenManagementMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const managementMenuData: IMenuData[] = useMemo(
    () =>
      showSendNotificationsButton
        ? [
            {
              label: 'Импортировать тома',
              action: () => onUploadExcelClick(),
              notCloseAfterClick: false,
            },
            {
              label: 'Импортировать PDF файлы ',
              action: () => onUploadFilesClick(),
              notCloseAfterClick: false,
            },
            {
              label: 'Экспортировать документацию',
              action: () => onExportArchiveOpenClick(),
              notCloseAfterClick: false,
            },
            {
              label: 'Уведомить подрядчиков',
              action: () => onSendNotificationsOpenClick && onSendNotificationsOpenClick(),
              notCloseAfterClick: false,
            },
          ]
        : [
            {
              label: 'Импортировать тома',
              action: () => onUploadExcelClick(),
              notCloseAfterClick: false,
            },
            {
              label: 'Импортировать PDF файлы ',
              action: () => onUploadFilesClick(),
              notCloseAfterClick: false,
            },
            {
              label: 'Экспортировать документацию',
              action: () => onExportArchiveOpenClick(),
              notCloseAfterClick: false,
            },
          ],
    [
      onExportArchiveOpenClick,
      onSendNotificationsOpenClick,
      onUploadExcelClick,
      onUploadFilesClick,
      showSendNotificationsButton,
    ],
  )
  // clear selected rd id when it does not available
  useEffect(() => {
    const firstRdId = selectedRdsIds[0]
    if (!firstRdId || !availableRdsItems?.length) return

    const rdIdIsAvailable = !!availableRdsItems.find((item) => item.value === firstRdId)

    if (!rdIdIsAvailable) {
      dispatch(clearFilter({}))
    }
  }, [availableRdsItems, dispatch, selectedRdsIds])

  useEffect(() => {
    const cachedPagesViewMode: PagesViewMode = JSON.parse(localStorage.getItem('pagesViewMode') || '{}')
    if (cachedPagesViewMode?.docs) {
      dispatch(setPageViewMode({ page: 'docs', viewMode: cachedPagesViewMode.docs }))
    }
  }, [])

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ py: 1.5 }}>
        <Stack direction='row' alignItems='center' width={'100%'} spacing={1.25} justifyContent='space-between'>
          {!userWithLessControls && (
            <DocsTopLegendStyledButton
              variant='outlined'
              color='secondary'
              startIcon={<AddIcon />}
              onClick={onAddClick}
            >
              Создать Том
            </DocsTopLegendStyledButton>
          )}
          <Stack marginLeft={'auto'} direction='row' alignItems='center' spacing={1.25}>
            {!userWithLessControls && (
              <>
                <DocsTopLegendStyledButton
                  onClick={onOpenManagementMenu}
                  variant='outlined'
                  color='secondary'
                  endIcon={<MenuIcon />}
                  sx={{
                    maxWidth: '100%',
                    padding: '8px 8px 6px 16px',
                    justifyContent: 'space-between',
                    minWidth: '260px',
                  }}
                >
                  {btnManagementTxt}
                </DocsTopLegendStyledButton>

                <Menu
                  anchorEl={anchorEl}
                  title={btnManagementTxt}
                  onClose={onCloseManagementMenu}
                  menuData={managementMenuData}
                />
              </>
            )}
            <ViewModeButtonGroup viewMode={viewMode} onViewChange={onViewChange} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
