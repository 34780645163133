import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import styled from 'styled-components'

export interface StyledToggleButtonGroupProps {
  size?: 40 | 36 | 30
}

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)<StyledToggleButtonGroupProps>`
  width: 100%;
  height: ${(props) => (props?.size ? props?.size + 'px' : '30px')};

  border-radius: 8px;
`

export interface StyledToggleButtonProps {
  variant?: 'primary' | 'shades'
}

export const StyledToggleButton = styled(ToggleButton)<StyledToggleButtonProps>`
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
  padding: 0;
  gap: 4px;

  outline: 0.5px solid ${(props) => props.theme.palette.primary.light};
  color: ${(props) =>
    props.variant === 'shades' ? props.theme.palette.secondary.main : props.theme.palette.primary.main};
  background-color: ${(props) => props.theme.palette.bg.white};
  background-color: ${(props) =>
    props.variant === 'shades' ? props.theme.palette.bg.white : props.theme.palette.bg.shades};
  & svg {
    color: ${(props) =>
      props.variant === 'shades' ? props.theme.palette.secondary.main : props.theme.palette.primary.main} !important;
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.bg.gray};
    background-color: ${(props) => (props.variant === 'shades' ? props.theme.palette.bg.shades : '')};
  }
`
