import { api } from '../../api'
import {
  CreateIiRequest,
  DeleteIiRequest,
  EditIiRequest,
  ExportIiRequest,
  GetAllIiRequest,
  GetAllIiResponse,
  GetIiByIdRequest,
  GetIiLinkExampleRequest,
  GetIiLinkExampleResponse,
  IiBaseResponse,
  UploadIiRequest,
  UploadIiResponse,
} from './iiApi.types'

export const iiApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllIi: build.query<GetAllIiResponse, GetAllIiRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/ii/list`,
        params,
        method: 'GET',
      }),
      providesTags: (result) =>
        result?.data?.length
          ? [
            ...result.data.map(({ id }) => ({ type: 'Ii' as const, id })),
            { type: 'Ii', id: 'LIST' },
            { type: 'Ii', id: 'PARTIAL-LIST' },
          ]
          : [
            { type: 'Ii', id: 'LIST' },
            { type: 'Ii', id: 'PARTIAL-LIST' },
          ],
    }),
    createIi: build.mutation<IiBaseResponse, CreateIiRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/ii/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Ii', id: 'LIST' }, { type: 'TomIi', id: 'SIDEBAR_MENU' }],
    }),
    uploadIi: build.mutation<UploadIiResponse, UploadIiRequest>({
      query: ({ id, file }) => {
        const formData = new FormData()
        formData.append('file', file)

        return {
          url: `/project/${id}/ii/upload`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: [{ type: 'TomIi', id: 'SIDEBAR_MENU' }]
    }),
    exportIi: build.mutation<Blob, ExportIiRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/ii/export-excel-file`,
        method: 'POST',
        responseHandler: async (response: any) => await response.blob(),
      }),
    }),
    getIiById: build.query<IiBaseResponse, GetIiByIdRequest>({
      query: ({ id, iiId }) => ({
        url: `/project/${id}/ii/${iiId}/get`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => {
        return [{ type: 'Ii', id: arg.id }]
      },
    }),
    editIi: build.mutation<IiBaseResponse, EditIiRequest>({
      query: ({ id, iiId, ...body }) => ({
        url: `/project/${id}/ii/${iiId}/update`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            iiApi.util.invalidateTags(['Ii'])
          )

        } catch {
        }
      },
      invalidatesTags: [{ type: 'TomIi', id: 'SIDEBAR_MENU' }]
    }),
    deleteIi: build.mutation<IiBaseResponse, DeleteIiRequest>({
      query: ({ id, iiId }) => ({
        url: `/project/${id}/ii/${iiId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Ii', id: 'PARTIAL-LIST' }, { type: 'TomIi', id: 'SIDEBAR_MENU' }],
    }),
    getIiLinkExample: build.mutation<GetIiLinkExampleResponse, GetIiLinkExampleRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/ii/link-example`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllIiQuery,
  useCreateIiMutation,
  useUploadIiMutation,
  useExportIiMutation,
  useGetIiByIdQuery,
  useEditIiMutation,
  useDeleteIiMutation,
  useGetIiLinkExampleMutation,
} = iiApi
