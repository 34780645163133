import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"

const TANGL_AUTH_SERVER = "https://auth.tangl.cloud"
const DEMO_CREDENTIALS = {
  client_id: "e35e3f8b-8197-5b4d-8249-3a077cfedc50", //Demo API client
  client_secret: "ff7eb513-db87-b66d-f743-3a077cfedc51", //Demo API client
  grant_type: "password",
  username: "devdemo@tangl.cloud", //Demo account
  password: "Devdemo1!", //Demo account
}


export const authTanglApi = createApi({
  reducerPath: 'authTanglApi',
  baseQuery: fetchBaseQuery({ baseUrl: TANGL_AUTH_SERVER }),
  endpoints: (build) => ({
    fetchToken: build.mutation({
      query: () => ({
        url: '/connect/token',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(DEMO_CREDENTIALS).toString(),
      }),
    }),
    fetchCompanies: build.query({
      query: ({ token }) => {
        return {
          url: `/api/app/company`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: 'GET',
        }
      },
      providesTags: ['TanglCompanies']
    }),
  }),
  tagTypes: ['TanglCompanies']
})

export const { useFetchTokenMutation, useFetchCompaniesQuery } = authTanglApi