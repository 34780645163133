import {
  Delete as DeleteIcon,
  ErrorOutlineOutlined as ErrorIcon,
  EventNote as EventNoteIcon,
} from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { useGetProjectByIdQuery } from 'api/projects'
import { IUploadTomRdChangeBodyRequest } from 'api/rdPhase'
import { PenEditIcon } from 'assets/icons/PenEditIcon'
import PersonWorkerIcon from 'assets/icons/PersonWorkerIcon'
import Button from 'components/Button'
import FieldForm from 'components/FieldForm'
import FileUpload from 'components/FileUpload'
import { UploadPropsData } from 'components/FileUpload/FileUpload.types'
import { Form, FormikProvider } from 'formik'
import useFilteredEmployees from 'hooks/useFilteredEmployees'
import { useForm } from 'hooks/useForm'
import { isEqual } from 'lodash'
import { useDeleteDoc } from 'pages/DocView/components/DocList/hooks/useDeleteDoc'
import { ModelAutocomplete } from 'pages/Models/components/DrawerInfoForm'
import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { selectedProjectPhaseSelector } from 'store/slices/documentsPages/projectInfo'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { formatDateToString, formatToDate } from 'utils/formatDate'

import { useDocsSubmit } from '@pages/Docs'

import {
  ChangeFieldForm,
  IDocsChangeFormData,
  IDocsChangeFormProps,
  TChangeStatus,
  TOM_CHANGE_DEFAULT_STATUS,
  changeStatuses,
  changeStatusesIrd,
  validationDocsChange,
} from '.'
import { DocsChangeFormFieldItem } from '../DocsChangeFormFieldItem'

const today = new Date(new Date().setHours(0, 0, 0, 0))

const uploadData: UploadPropsData = {
  title: 'Загрузить PDF',
  text: 'Чтобы добавить документ к тому, выберите его из папки на компьютере или просто перетяните его в эту область.',
  formats: ['application/pdf'],
}

export const DocsChangeForm: FC<IDocsChangeFormProps> = ({ onFormChange, currentChange, onClose }) => {
  const { change: changeData, doc } = currentChange || {}
  const { change = null } = doc || {}
  const {
    status = null,
    architector = null,
    plannedDate,
    actualDate = null,
    durationDate = null,
    description = null,
    issueDate = null,
    prodDate = null,
    inProd = false,
  } = changeData || {}
  const profile = useTypedSelector(profileSelector)
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { role } = profile
  const isAdminOrClient: boolean = role === 'admin' || role === 'client'
  const isArchitector: boolean = role === 'architector'
  const { data: projectData } = useGetProjectByIdQuery({ id: projectId }, { skip: !isAdminOrClient })
  const { architectorsAndAdmins } = useFilteredEmployees()
  const { onAddChange, onUpdateChange } = useDocsSubmit()
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector, isEqual)
  const isIrd: boolean = selectedProjectPhase === 'Сбор исходных данных'
  const isRd: boolean = selectedProjectPhase === 'Рабочая документация'
  const { onDeleteChangeClick, ConfirmDialog } = useDeleteDoc()
  const [isStatusHandled, setIsStatusHandled] = useState<boolean>(true)

  const initialArchitector = useMemo(() => {
    if (architector) return architector

    if (currentChange) return

    if (isAdminOrClient) {
      const { data } = projectData || {}
      const { responsiblePersons } = data || {}
      const { architector: projectArchitector } = responsiblePersons || {}

      return {
        id: projectArchitector?.id || null,
        name:
          projectArchitector?.name ||
          architectorsAndAdmins.find((item) => item.id === projectArchitector?.id)?.name ||
          '',
      }
    }

    if (isArchitector) {
      const architectorData = architectorsAndAdmins.find((item) => item.id === profile.employeeId)

      return { id: architectorData?.id || null, name: architectorData?.name || '' }
    }
  }, [isAdminOrClient, isArchitector, projectData, architector, currentChange, architectorsAndAdmins])

  // @ts-ignore
  const initialValues: IDocsChangeFormData = useMemo(
    () => ({
      mode: currentChange ? 'edit' : 'add',
      inProd: Boolean(inProd),
      change: change ? String(change) : null,
      status: currentChange ? status || TOM_CHANGE_DEFAULT_STATUS : null,
      architector: initialArchitector || null,
      plannedDate: formatToDate(plannedDate) || null,
      actualDate: formatToDate(actualDate) || null,
      durationDate: formatToDate(durationDate) || null,
      issueDate: formatToDate(issueDate) || null,
      prodDate: formatToDate(prodDate) || null,
      files: null,
      description,
    }),
    [initialArchitector, currentChange],
  )

  const onSubmit = useCallback((values: IDocsChangeFormData) => {
    const {
      status,
      files,
      actualDate,
      architector,
      change,
      description,
      plannedDate,
      issueDate,
      durationDate,
      prodDate,
    } = values

    const dataForRequest: IUploadTomRdChangeBodyRequest = {
      architector: architector || null,
      change: Number(change),
      description,
      plannedDate: plannedDate ? formatDateToString(plannedDate, 'dd.MM.yyyy') : null,
      actualDate: actualDate ? formatDateToString(actualDate, 'dd.MM.yyyy') : null,
      issueDate: issueDate ? formatDateToString(issueDate, 'dd.MM.yyyy') : null,
      durationDate: durationDate ? formatDateToString(durationDate, 'dd.MM.yyyy') : null,
      prodDate: prodDate ? formatDateToString(prodDate, 'dd.MM.yyyy') : null,
      status: status!,
    }

    if (values.mode === 'add') onAddChange({ files, change: dataForRequest })
    else onUpdateChange({ changeNum: currentChange!.doc.change, change: dataForRequest, files: null })
  }, [])

  const { formik } = useForm({
    validationSchema: validationDocsChange,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
    },
  })

  const { values, dirty, isValid, setFieldValue, setTouched, isSubmitting } = formik
  const showProdDateField: boolean = values.inProd && isRd && values.status === 'Согласовано'

  useEffect(() => {
    onFormChange(dirty)
  }, [values])

  useEffect(() => {
    if (isStatusHandled) return

    setTouched({ actualDate: true })
    setIsStatusHandled(true)
  }, [isStatusHandled])

  useEffect(() => {
    if (!values.change) return

    if (values.change.split('').every((item) => item === '0')) {
      setFieldValue('change', null)
    }
  }, [values.change])

  const onStatusChange = (e: SyntheticEvent, data: any) => {
    const prevStatus: TChangeStatus | null = values.status
    const nextStatus: TChangeStatus | null = data?.value || null

    if (nextStatus === 'Согласовано') {
      setFieldValue('actualDate', today)
    } else {
      setFieldValue('actualDate', null)
      setFieldValue('prodDate', null)
    }

    if (nextStatus === 'Не согласуется') {
      setFieldValue('plannedDate', null)
    } else if (prevStatus === 'Не согласуется') {
      setFieldValue('durationDate', null)
      setFieldValue('issueDate', null)
    }

    setFieldValue('status', nextStatus)
    setIsStatusHandled(false)
  }

  return (
    <FormikProvider value={formik}>
      <Stack component={Form} height={'100%'} overflow={'auto'}>
        <Stack p={2.5} height={'100%'} overflow={'auto'} spacing={3}>
          <Stack spacing={1.5}>
            <DocsChangeFormFieldItem title='Изменение' icon={<PenEditIcon />} isRequired>
              <ChangeFieldForm
                name='change'
                version='doc'
                placeholder='№'
                type='number'
                helperText=''
                onKeyDown={(e) => {
                  const stopKey: string[] = ['-', 'e', '.', ',', '+']
                  if (stopKey.includes(e.key)) {
                    e.preventDefault()
                  }
                }}
                onChange={(e) => setFieldValue('change', e.target.value.slice(0, 5))}
              />
            </DocsChangeFormFieldItem>

            <DocsChangeFormFieldItem title='Статус' icon={<ErrorIcon fontSize='small' color='secondary' />} isRequired>
              <ModelAutocomplete
                fieldName='status'
                placeholder='Выберите из списка'
                $width={'180px'}
                // @ts-ignore
                onChange={onStatusChange}
                customRenderOption={(props, option) => (
                  <Box component='li' {...props} key={option.key}>
                    <Typography variant='body2'>{option.label}</Typography>
                  </Box>
                )}
                data={(isIrd ? changeStatusesIrd : changeStatuses).map((item) => ({
                  value: item,
                  label: item,
                  key: item,
                }))}
              />
            </DocsChangeFormFieldItem>

            <DocsChangeFormFieldItem
              title='Проектировщик'
              icon={<PersonWorkerIcon fontSize='small' color='secondary' />}
            >
              <ModelAutocomplete
                fieldName='architector'
                placeholder='Выберите из списка'
                $width={'180px'}
                value={values.architector?.name}
                customRenderOption={(props, option) => (
                  <Box component='li' {...props} key={option.key}>
                    <Typography variant='body2'>{option.label}</Typography>
                  </Box>
                )}
                // @ts-ignore
                data={architectorsAndAdmins.map(({ id, name }) => ({
                  value: { id, name: name },
                  label: name,
                  key: id,
                }))}
              />
            </DocsChangeFormFieldItem>
          </Stack>

          <Stack spacing={2}>
            <Typography variant='body2'>{values.status !== 'Не согласуется' ? 'Даты согласования' : 'Даты'}</Typography>

            {values.status !== 'Не согласуется' && (
              <DocsChangeFormFieldItem
                title='Плановая дата'
                icon={<EventNoteIcon fontSize='small' color='secondary' />}
              >
                <ChangeFieldForm
                  name='plannedDate'
                  version='date'
                  placeholder='№'
                  dataValue={values.plannedDate}
                  onDataChange={(value: Date | null) => setFieldValue('plannedDate', value)}
                  style={{ width: '180px' }}
                  inputProps={{
                    style: { height: '18px' },
                    placeholder: 'дд.мм.гггг',
                  }}
                  helperText=''
                />
              </DocsChangeFormFieldItem>
            )}

            {values.status === 'Согласовано' && (
              <DocsChangeFormFieldItem
                title='Фактическая дата'
                icon={<EventNoteIcon fontSize='small' color='secondary' />}
                isRequired
              >
                <ChangeFieldForm
                  name='actualDate'
                  version='date'
                  placeholder='№'
                  dataValue={values.actualDate}
                  onDataChange={(value: Date | null) => setFieldValue('actualDate', value)}
                  style={{ width: '180px' }}
                  inputProps={{
                    style: { height: '18px' },
                    placeholder: 'дд.мм.гггг',
                  }}
                  dateFieldProps={{
                    maxDate: today || undefined,
                  }}
                  helperText=''
                />
              </DocsChangeFormFieldItem>
            )}

            {values.status === 'Не согласуется' && (
              <>
                <DocsChangeFormFieldItem
                  title='Дата выдачи'
                  icon={<EventNoteIcon fontSize='small' color='secondary' />}
                  isRequired
                >
                  <ChangeFieldForm
                    name='issueDate'
                    version='date'
                    placeholder='№'
                    dataValue={values.issueDate}
                    onDataChange={(value: Date | null) => setFieldValue('issueDate', value)}
                    style={{ width: '180px' }}
                    inputProps={{
                      style: { height: '18px' },
                      placeholder: 'дд.мм.гггг',
                    }}
                    helperText=''
                  />
                </DocsChangeFormFieldItem>
                <DocsChangeFormFieldItem
                  title='Срок действия'
                  icon={<EventNoteIcon fontSize='small' color='secondary' />}
                >
                  <ChangeFieldForm
                    name='durationDate'
                    version='date'
                    placeholder='№'
                    dataValue={values.durationDate}
                    onDataChange={(value: Date | null) => setFieldValue('durationDate', value)}
                    style={{ width: '180px' }}
                    inputProps={{
                      style: { height: '18px' },
                      placeholder: 'дд.мм.гггг',
                    }}
                    helperText=''
                  />
                </DocsChangeFormFieldItem>
              </>
            )}

            {showProdDateField && (
              <DocsChangeFormFieldItem
                title='В производстве'
                icon={<EventNoteIcon fontSize='small' color='secondary' />}
                isRequired
              >
                <ChangeFieldForm
                  name='prodDate'
                  version='date'
                  placeholder='№'
                  dataValue={values.prodDate}
                  onDataChange={(value: Date | null) => setFieldValue('prodDate', value)}
                  style={{ width: '180px' }}
                  inputProps={{
                    style: { height: '18px' },
                    placeholder: 'дд.мм.гггг',
                  }}
                  helperText=''
                />
              </DocsChangeFormFieldItem>
            )}
          </Stack>

          {!currentChange && <FileUpload name='files' mode='single' uploadData={uploadData} loading={false} />}

          <FieldForm
            version='project'
            name='description'
            multiline
            placeholder={'Комментарий'}
            inputProps={{
              style: {
                minHeight: '60px',
              },
            }}
          />
        </Stack>

        <Stack p={2.5} spacing={2} alignItems={'center'}>
          <Stack direction={'row'} spacing={2} width={'100%'}>
            <Button loading={isSubmitting} type='submit' disabled={!dirty || !isValid} color='success' fullWidth>
              Сохранить
            </Button>

            <Button fullWidth onClick={() => onClose(dirty)}>
              Отменить
            </Button>
          </Stack>
          {currentChange && (
            <Box style={{ width: 'fit-content' }}>
              <Button
                onClick={() => onDeleteChangeClick(currentChange.doc)}
                startIcon={<DeleteIcon />}
                variant='text'
                color='error'
              >
                Удалить изменение
              </Button>
            </Box>
          )}
        </Stack>
      </Stack>

      <ConfirmDialog />
    </FormikProvider>
  )
}
