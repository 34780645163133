import React from 'react'
import { ProgressWrapper, StyledButton } from './styles'
import { ButtonProps } from './Button.types'
import { CircularProgress } from '@mui/material'

const Button: React.FC<ButtonProps> = ({
  customSize = 'small',
  paddingSize = 'medium',
  color = 'primary',
  variant = 'contained',
  children,
  loading,
  hoverBgColor,
  ...props
}) => {
  return (
    <StyledButton
      variant={variant}
      color={color}
      customSize={customSize}
      paddingSize={paddingSize}
      disableRipple
      loading={loading}
      hoverBgColor={hoverBgColor}
      {...props}
    >
      {loading && (
        <ProgressWrapper>
          <CircularProgress size={25} color='inherit' />
        </ProgressWrapper>
      )}
      {children}
    </StyledButton>
  )
}

export default Button
