import { MutableRefObject, RefObject } from "react"
import { AlignmentType } from "./components/ZoomComponent"

export const DEFAULT_MARGIN = 10

export interface PragmaPdfViewerProps {
  file: string
  fileName?: string
  isOpenRemarkForm: boolean
  actionButtonRemark?: (page: number, numPages: number) => void
  remarkAnnotations: IncommingAnnotation[]
  pageOfSelectedRemark: {page: number, selectedId: number} | null
}

export interface LazyPageProps {
  pageIndex: number
  scale: number
  currentPage: number
  defaultHeight: number
  scrollContainerRef: RefObject<HTMLDivElement>
  widthAlignment: AlignmentType
  rotate: number
  handTool: boolean
  pageData: MutableRefObject<PageData[]>
}

export interface PageData {
  pageNumber: number
  width: number
  height: number
  scaleFactor: number
}

export type AnnotationType = 'arrow' | 'rectangle' | 'highlight' | 'text'

export interface AnnotationRects {
  left: number
  top: number
  width: number
  height: number
}

export interface Annotation {
  color: ColorNotes
  rects?: AnnotationRects
  highlightRects?: AnnotationRects[]
  rotate: number
  text?: string
  type: AnnotationType
  id: number | null
}

export interface IncommingAnnotation {
  page: number
  annotations: Annotation[]
  remarkId: number
}

export type ColorNotes = 'red' | 'redbg' | 'blue' | 'gray'
export interface HexColorNotes extends Record<ColorNotes, string> {}

export const colorNotes: HexColorNotes = {
  'red': '#F46B6B',
  'redbg': '#FFB1B126',
  'blue' : '#0044b4',
  'gray': '#5c6e8c'
}