import { useSnackbar } from 'notistack'
import { useCallback, useState } from 'react'
import { setAllowScrolling } from 'store/slices/remarks/remarks'
import { docsViewSelector } from 'store/slices/ui'

import { DocFormData } from '@pages/Docs'

import {
  TomIiBaseResponse,
  TomIiRequest,
  TomIiVersionBaseResponse,
  useCreateTomIiMutation,
  useCreateTomIiVersionMutation,
  useEditTomIiMutation,
} from '../../../../api/iiPhase'
import {
  TomIrdBaseResponse,
  TomIrdRequest,
  TomIrdVersionBaseResponse,
  useCreateTomIrdMutation,
  useCreateTomIrdVersionMutation,
  useEditTomIrdMutation,
} from '../../../../api/irdPhase'
import {
  TomPdBaseResponse,
  TomPdRequest,
  TomPdVersionBaseResponse,
  useCreateTomPdMutation,
  useCreateTomPdVersionMutation,
  useEditTomPdMutation,
} from '../../../../api/pdPhase'
import {
  TomRdBaseResponse,
  TomRdRequest,
  TomRdVersionBaseResponse,
  useCreateTomRdMutation,
  useCreateTomRdVersionMutation,
  useEditTomRdMutation,
} from '../../../../api/rdPhase'
import useFilteredEmployees from '../../../../hooks/useFilteredEmployees'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { openedDrawerSelector } from '../../../../store/slices/documentsPages/drawerInfo'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector,
} from '../../../../store/slices/documentsPages/projectInfo'
import { tomSelector } from '../../../../store/slices/documentsPages/tom'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { isRdTom } from '../../../../types/tom'
import { DATE_FORMAT } from '../../../../utils/constants'
import { formatDateToString } from '../../../../utils/formatDate'
import { CommonIrdTomRequest, CommonTomRequest, UseTomSubmitProps, UseTomSubmitResponse } from './useTomSubmit.types'

export const useTomSubmit = ({
  setResponseData,
  onDrawerClose,
  onSuccessCreate,
  otherPhase,
  otherProjectID,
}: UseTomSubmitProps): UseTomSubmitResponse => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { tom } = useTypedSelector(tomSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const viewMode = useTypedSelector(docsViewSelector)

  const projectPhase = otherPhase || selectedProjectPhase
  const projectId = otherProjectID || project.id
  const isEditTom = openedDrawer === 'update' && !!tom?.id
  const { id } = (isEditTom && tom) || {}

  const { prodDate, prodAuthorId, prodAuthorName } = (isEditTom && tom && isRdTom(tom) && tom) || {}

  const { architectorsAndAdmins, clientsAndAdmins, contractors } = useFilteredEmployees()

  const [tomFile, setTomFile] = useState<File | null>(null)

  // Rd
  const [createTomRd, createTomRdResponse] = useCreateTomRdMutation()
  const [editTomRd, editTomRdResponse] = useEditTomRdMutation()
  const [uploadRdDocument, { isLoading: isFileRdLoading, ...uploadRdDocumentResponse }] =
    useCreateTomRdVersionMutation()

  const onRdSubmit = useCallback(
    (values: DocFormData, commonTomRequest: CommonTomRequest) => {
      const { objectId, rdId, contractorData } = values
      if (!(objectId && rdId)) return

      const dataForRequest: TomRdRequest = {
        ...commonTomRequest,
        objectId,
        rdId,
        contractorId: typeof contractorData === 'number' ? contractorData : null,
        contractorName:
          (typeof contractorData === 'number'
            ? contractors.find((contractor) => contractor.id === contractorData)?.name
            : contractorData) || '',
        prodDate: (values.prodDate && formatDateToString(values.prodDate, DATE_FORMAT)) || null,
        prodAuthorId: prodAuthorId || null,
        prodAuthorName: prodAuthorName || '',
      }

      if (id) {
        editTomRd({
          id,
          ...dataForRequest,
        })
      } else {
        createTomRd({
          id: projectId,
          ...dataForRequest,
        })
      }
    },
    [tom, architectorsAndAdmins, clientsAndAdmins, contractors],
  )

  useMutationHandlers(createTomRdResponse, (data: TomRdBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    if (tomFile) {
      uploadRdDocument({
        id: data.data.id,
        file: tomFile,
      })
    } else {
      onDrawerClose(false, true, true)
      onSuccessCreate?.(data.data)
    }
    viewMode === 'table' &&
      !id &&
      dispatch(
        setAllowScrolling({
          allowScrolling: {
            allowScrolling: true,
            mode: 'create',
            id: data.data.id,
          },
        }),
      )
  })

  useMutationHandlers(uploadRdDocumentResponse, (data: TomRdVersionBaseResponse) => {
    setResponseData(data)

    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    onDrawerClose(false, true, false, data)
  })

  useMutationHandlers(editTomRdResponse, (data: TomRdBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    onDrawerClose(false, true, true)
  })

  // Pd
  const [createTomPd, createTomPdResponse] = useCreateTomPdMutation()
  const [editTomPd, editTomPdResponse] = useEditTomPdMutation()
  const [uploadPdDocument, { isLoading: isFilePdLoading, ...uploadPdDocumentResponse }] =
    useCreateTomPdVersionMutation()

  const onPdSubmit = useCallback(
    (values: DocFormData, commonTomRequest: CommonTomRequest) => {
      const { pdId } = values
      if (!pdId) return

      const dataForRequest: TomPdRequest = {
        ...commonTomRequest,
        pdId,
      }

      if (id) {
        editTomPd({
          id,
          ...dataForRequest,
        })
      } else {
        createTomPd({
          id: projectId,
          ...dataForRequest,
        })
      }
    },
    [tom, architectorsAndAdmins, clientsAndAdmins, contractors],
  )

  useMutationHandlers(createTomPdResponse, (data: TomPdBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    if (tomFile) {
      uploadPdDocument({
        id: data.data.id,
        file: tomFile,
      })
    } else {
      onDrawerClose(false, true, true)
      onSuccessCreate?.(data.data)
    }
    viewMode === 'table' &&
      !id &&
      dispatch(
        setAllowScrolling({
          allowScrolling: {
            allowScrolling: true,
            mode: 'create',
            id: data.data.id,
          },
        }),
      )
  })

  useMutationHandlers(uploadPdDocumentResponse, (data: TomPdVersionBaseResponse) => {
    setResponseData(data)

    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    onDrawerClose(false, true, false, data)
  })

  useMutationHandlers(editTomPdResponse, (data: TomPdBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    onDrawerClose(false, true, true)
  })

  // Ii
  const [createTomIi, createTomIiResponse] = useCreateTomIiMutation()
  const [editTomIi, editTomIiResponse] = useEditTomIiMutation()
  const [uploadIiDocument, { isLoading: isFileIiLoading, ...uploadIiDocumentResponse }] =
    useCreateTomIiVersionMutation()

  const onIiSubmit = useCallback(
    (values: DocFormData, commonTomRequest: CommonTomRequest) => {
      const { iiId, iiType } = values
      if (!(iiId && iiType)) return

      const dataForRequest: TomIiRequest = {
        ...commonTomRequest,
        iiId,
        type: iiType,
      }

      if (id) {
        editTomIi({
          id,
          ...dataForRequest,
        })
      } else {
        createTomIi({
          id: projectId,
          ...dataForRequest,
        })
      }
    },
    [tom, architectorsAndAdmins, clientsAndAdmins, contractors],
  )

  useMutationHandlers(createTomIiResponse, (data: TomIiBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    if (tomFile) {
      uploadIiDocument({
        id: data.data.id,
        file: tomFile,
      })
    } else {
      onDrawerClose(false, true, true)
      onSuccessCreate?.(data.data)
    }
    viewMode === 'table' &&
      !id &&
      dispatch(
        setAllowScrolling({
          allowScrolling: {
            allowScrolling: true,
            mode: 'create',
            id: data.data.id,
          },
        }),
      )
  })

  useMutationHandlers(uploadIiDocumentResponse, (data: TomIiVersionBaseResponse) => {
    setResponseData(data)

    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    onDrawerClose(false, true, false, data)
  })

  useMutationHandlers(editTomIiResponse, (data: TomIiBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    onDrawerClose(false, true, true)
  })

  // Ird
  const [createTomIrd, createTomIrdResponse] = useCreateTomIrdMutation()
  const [editTomIrd, editTomIrdResponse] = useEditTomIrdMutation()
  const [uploadIrdDocument, { isLoading: isFileIrdLoading, ...uploadIrdDocumentResponse }] =
    useCreateTomIrdVersionMutation()

  const onIrdSubmit = useCallback(
    (values: DocFormData, commonTomRequest: CommonIrdTomRequest) => {
      const { irdId } = values
      if (!irdId) return

      const dataForRequest: TomIrdRequest = {
        ...commonTomRequest,
        irdId,
      }

      if (id) {
        editTomIrd({
          id,
          ...dataForRequest,
        })
      } else {
        createTomIrd({
          id: projectId,
          ...dataForRequest,
        })
      }
    },
    [tom, architectorsAndAdmins, clientsAndAdmins, contractors],
  )

  useMutationHandlers(createTomIrdResponse, (data: TomIrdBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    if (tomFile) {
      uploadIrdDocument({
        id: data.data.id,
        file: tomFile,
      })
    } else {
      onDrawerClose(false, true, true)
      onSuccessCreate?.(data.data)
    }
    viewMode === 'table' &&
      !id &&
      dispatch(
        setAllowScrolling({
          allowScrolling: {
            allowScrolling: true,
            mode: 'create',
            id: data.data.id,
          },
        }),
      )
  })

  useMutationHandlers(uploadIrdDocumentResponse, (data: TomIrdVersionBaseResponse) => {
    setResponseData(data)

    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    onDrawerClose(false, true, false, data)
  })

  useMutationHandlers(editTomIrdResponse, (data: TomIrdBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    onDrawerClose(false, true, true)
  })

  // Common
  const onSubmit = (values: DocFormData) => {
    const {
      title,
      description,
      status,
      architectorData,
      clientData,
      plannedDate,
      forecastDate,
      actualDate,
      issueDate,
      durationDate,
      files,
    } = values
    if (!status) return

    const commonTomRequest: CommonTomRequest = {
      title,
      description,
      status,
      architectorId: typeof architectorData === 'number' ? architectorData : null,
      architectorName:
        (typeof architectorData === 'number'
          ? architectorsAndAdmins.find((architector) => architector.id === architectorData)?.name
          : architectorData) || '',
      clientId: typeof clientData === 'number' ? clientData : null,
      clientName:
        (typeof clientData === 'number'
          ? clientsAndAdmins.find((client) => client.id === clientData)?.name
          : clientData) || '',
      plannedDate: null,
      forecastDate: null,
      actualDate: null,
    }
    if (projectPhase !== 'Сбор исходных данных' || status !== 'Не согласуется') {
      plannedDate && (commonTomRequest.plannedDate = formatDateToString(plannedDate, DATE_FORMAT))
      forecastDate && (commonTomRequest.forecastDate = formatDateToString(forecastDate, DATE_FORMAT))
      actualDate && (commonTomRequest.actualDate = formatDateToString(actualDate, DATE_FORMAT))
    }

    const commonIrdTomRequest: CommonIrdTomRequest = {
      ...commonTomRequest,
      issueDate: null,
      durationDate: null,
    }
    if (projectPhase === 'Сбор исходных данных' && status === 'Не согласуется') {
      issueDate && (commonIrdTomRequest.issueDate = formatDateToString(issueDate, DATE_FORMAT))
      durationDate && (commonIrdTomRequest.durationDate = formatDateToString(durationDate, DATE_FORMAT))
    }

    const file = files?.length && files[0]
    if (file instanceof File) {
      setTomFile(file)
    }

    switch (projectPhase) {
      case 'Рабочая документация':
        onRdSubmit(values, commonTomRequest)
        break
      case 'Проектная документация':
        onPdSubmit(values, commonTomRequest)
        break
      case 'Инженерные изыскания':
        onIiSubmit(values, commonTomRequest)
        break
      case 'Сбор исходных данных':
        onIrdSubmit(values, commonIrdTomRequest)
        break
    }
  }

  const fileLoading = isFileRdLoading || isFilePdLoading || isFileIiLoading || isFileIrdLoading

  return {
    onSubmit,
    fileLoading,
  }
}
