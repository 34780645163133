import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../../components/Header'
import { InnerContainer, MainContainer } from './styles'

const HomeLayout: React.FC = () => {
  return (
    <MainContainer>
      <Header />
      <InnerContainer>
        <Outlet />
      </InnerContainer>
    </MainContainer>
  )
}

export default HomeLayout