import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Box, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material'
import Button from 'components/Button'
import { useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { setRemarkGroupFilter } from '@store/slices/remarks/remarks'

import { useGetProjectByIdQuery, useGetProjectsQuery } from '../../api/projects'
import { GetProjectsResponse } from '../../api/projects/api.types'
import { useGetProjectRemarksQuery, useGetProjectTomsQuery, useGetRemarkMailTomsQuery } from '../../api/remarks'
import { RemarkStatus } from '../../api/remarks/types'
import Header from '../../components/Header'
import Progress from '../../components/Progress'
import Tabs from '../../components/Tabs'
import { TabData } from '../../components/Tabs/Tabs.types'
import { uiContext } from '../../contexts/ui'
import useBreadcrumbs from '../../hooks/useBreadcrumbs'
import useQuery from '../../hooks/useQuery'
import { ConfirmDialog } from '../../layouts/DocumentsLayout/components/ConfirmDialog'
import { InnerContainer, MainContainer } from '../../layouts/HomeLayout/styles'
import { onDrawerClose, openedDrawerSelector, setOpenedDrawer } from '../../store/slices/documentsPages/drawerInfo'
import { selectedProjectPhaseSelector } from '../../store/slices/documentsPages/projectInfo'
import {
  remarkGroupFilterSelector,
  remarkPhaseFilterSelector,
} from '../../store/slices/remarks/selectors/remarks.selectors'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { RemarkStatusLabelsRu, remarkStatusLabelsEnToRu, remarkStatusLabelsRuToEn } from '../../types/remarks'
import { determineTomType } from '../../types/tom'
import { remarkStatusRuArray, remarkStatusRuToEn } from '../DocView/components/DocViewRightSideBar/components/Remark'
import { HelpButton, StyledPopover } from './RemarksPage.styles'
import { ManualSubmitDrawer } from './components/ManualSubmitDrawer'
import { RemarkFilterDrawer } from './components/RemarkFilterDrawer'
import { RemarkHistoryDrawer } from './components/RemarkHistoryDrawer'
import { RemarksManagmentMenu } from './components/RemarksManagmentMenu'
import { RemarksTable } from './components/RemarksTable'
import { RemarkFormDrawer } from './components/RemarkFormDrawer'

export const RemarksPage = () => {
  const cUI = useContext(uiContext)
  const navigate = useNavigate()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const dispatch = useAppDispatch()
  const queryHandler = useQuery()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorElTooltip, setAnchorElTooltip] = useState<null | HTMLElement>(null)
  const MenuOpen = Boolean(anchorEl)
  const tooltipOpen = Boolean(anchorElTooltip)

  const {
    data: projectData,
    isLoading: projectLoading,
    isFetching: isProjectFetching,
  } = useGetProjectByIdQuery({ id: projectId })
  const { data: project } = projectData || {}
  const projectName = project?.shifrName || ''
  const loading = projectLoading || isProjectFetching
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const phaseFilter = useTypedSelector(remarkPhaseFilterSelector)
  const groupFilter = useTypedSelector(remarkGroupFilterSelector)

  const { data: projectsData, isLoading: projectsLoading, isFetching: projectsFetching } = useGetProjectsQuery({})
  const { data: projects } = projectsData || ({} as GetProjectsResponse)

  const { data: projectToms } = useGetProjectTomsQuery({ projectId, type: determineTomType(selectedProjectPhase) })

  const selectedStatus: RemarkStatus | null = queryHandler.get('status')
  const currentStatus: RemarkStatusLabelsRu = selectedStatus ? remarkStatusLabelsEnToRu[selectedStatus] : 'Все статусы'
  const [currentTab, setCurrentTab] = useState<RemarkStatusLabelsRu>(currentStatus)

  const { data: remarks } = useGetProjectRemarksQuery({
    projectId,
    status: currentTab !== 'Все статусы' ? (remarkStatusLabelsRuToEn[currentTab] as RemarkStatus) : undefined,
    type: determineTomType(selectedProjectPhase),
    group: groupFilter.group,
    obj: groupFilter.obj,
    second: groupFilter.second,
    authors: groupFilter.authors,
    companies: groupFilter.companies || undefined,
  })

  const ProjectBreadClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onMenuItemClick = useCallback(
    (projectId: number) => {
      dispatch(
        setRemarkGroupFilter({
          remarkGroupFilter: { ...groupFilter, obj: null, group: null, authors: null, companies: null },
        }),
      )
      navigate(`/project/${projectId}/remarks?phase=${phaseFilter}`)
      onClose()
    },
    [onClose, phaseFilter, groupFilter],
  )

  useBreadcrumbs(
    [
      {
        title: projectName,
        onClick: (e) => ProjectBreadClick(e),
        MenuOpen,
        type: 'project',
      },
      { title: 'Реестр замечаний' },
    ],
    [MenuOpen, projectName],
    loading,
  )

  useLayoutEffect(() => {
    cUI.setShowSearch(false)
    return () => {
      cUI.setShowSearch(true)
    }
  }, [])

  const onTabChange = (e: React.SyntheticEvent, tabValue: RemarkStatusLabelsRu) => {
    setCurrentTab(tabValue)
    const allStatuses = { ...remarkStatusRuToEn, 'Все статусы': 'all' }
    phaseFilter && queryHandler.set('phase', phaseFilter)
    queryHandler.set('status', allStatuses[tabValue])
  }

  const tabsData: TabData<RemarkStatusLabelsRu>[] = useMemo(() => {
    let tabs: RemarkStatusLabelsRu[] = ['Все статусы', ...remarkStatusRuArray]
    if (!remarks) return []
    return tabs.map((tabName) => {
      const remarkDataCount = remarks.count[remarkStatusLabelsRuToEn[tabName]]

      return {
        value: tabName,
        label: `${tabName} (${remarkDataCount})`,
        disabled: remarkDataCount === 0,
      }
    })
  }, [remarks])

  const closeDrawer = (dirty: boolean, immediately?: boolean) => {
    dispatch(onDrawerClose({ dirty, immediately }))
  }

  const openAddRemarkDrawer = () => dispatch(setOpenedDrawer({ openedDrawer: 'remarks' }))

  return (
    <>
      <RemarkFilterDrawer />
      <MainContainer>
        <Header />
        <InnerContainer>
          <Stack width='100%'>
            <Stack direction='row' pt={1} pb={1.75} spacing={1} justifyContent='space-between'>
              <>
                <div>
                  {projectToms && projectToms.data.length > 0 && (
                    <Button onClick={openAddRemarkDrawer} startIcon={<AddIcon />} variant='outlined' color='secondary'>
                      Создать замечание
                    </Button>
                  )}
                </div>
                <Stack direction={'row'} spacing={1}>
                  <RemarksManagmentMenu
                    data={{
                      project,
                      selectedProjectPhase,
                      selectedStatus,
                      showManualSubmit: true,
                    }}
                  />

                  <HelpButton onClick={(e) => setAnchorElTooltip(anchorElTooltip ? null : e.currentTarget)}>
                    <HelpOutlineIcon />
                  </HelpButton>
                  <StyledPopover
                    open={tooltipOpen}
                    anchorEl={anchorElTooltip}
                    onClose={() => setAnchorElTooltip(null)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  >
                    <Box p={2}>
                      <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='h2' fontWeight={500} mb={1.5}>
                          Настройка отображения таблицы
                        </Typography>
                        <IconButton onClick={() => setAnchorElTooltip(null)} size='small' sx={{ height: 30 }}>
                          <CloseIcon fontSize='medium' />
                        </IconButton>
                      </Stack>
                      <Typography variant='body2'>
                        Для изменения ширины колонки наведите курсор на разделители колонок в шапке таблицы. Настройки
                        сохранятся после обновления страницы. Помимо изменения ширины доступен функционал изменения
                        порядка колонок.
                      </Typography>
                      <Typography variant='body2'>
                        По клику на наименование колонки № доступна сортировка списка замечаний.
                      </Typography>
                    </Box>
                  </StyledPopover>
                </Stack>
              </>
            </Stack>
            <Stack direction='row' justifyContent='space-between'>
              <Tabs<RemarkStatusLabelsRu> currentTab={currentTab} onTabChange={onTabChange} tabsData={tabsData} />
            </Stack>
            <RemarksTable remarks={remarks?.data} />
          </Stack>
        </InnerContainer>
      </MainContainer>
      <Menu
        anchorEl={anchorEl}
        open={MenuOpen}
        onClose={onClose}
        MenuListProps={{
          style: {
            width: '100%',
          },
        }}
        PaperProps={{
          style: {
            display: 'flex',
            justifyContent: 'center',
            minWidth: 200,
            maxWidth: 400,
            maxHeight: 300,
          },
        }}
      >
        {projectsLoading || projectsFetching ? (
          <Progress />
        ) : projects?.length > 1 ? (
          projects
            .filter((project) => project.id !== projectId!)
            ?.map((project) => (
              <MenuItem onClick={() => onMenuItemClick(project.id)} style={{ maxWidth: 450 }} key={project.id}>
                <Typography
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {project.shifrName}
                </Typography>
              </MenuItem>
            ))
        ) : (
          <MenuItem style={{ maxWidth: 450 }} disabled>
            <Typography>Другие проекты отсутствуют</Typography>
          </MenuItem>
        )}
      </Menu>
      <RemarkHistoryDrawer open={openedDrawer === 'remarksHistory'} closeDrawer={closeDrawer} />
      <RemarkFormDrawer
        tomId={undefined}
        type={determineTomType(selectedProjectPhase)}
      />
      <ManualSubmitDrawer
        open={openedDrawer === 'manualSumbitRemarks'}
        selectedPhase={determineTomType(selectedProjectPhase)}
        closeDrawer={closeDrawer}
      />
      <ConfirmDialog />
    </>
  )
}
