import { useMemo } from 'react'

import { GetTomsIiFullResponse, TomIiFull, useGetTomsIiFullQuery } from '../../../../api/iiPhase'
import { TomIrdFull, useGetTomsIrdFullQuery } from '../../../../api/irdPhase'
import { GetTomsPdFullResponse, TomPdFull, useGetTomsPdFullQuery } from '../../../../api/pdPhase'
import { GetTomsRdFullResponse, TomRdFull, useGetTomsRdFullQuery } from '../../../../api/rdPhase'
import { TabData } from '../../../../components/Tabs/Tabs.types'
import useSearch from '../../../../hooks/useSearch'
import { filterDataSelector } from '../../../../store/slices/documentsPages/filter'
import { selectedProjectPhaseSelector } from '../../../../store/slices/documentsPages/projectInfo'
import { useTypedSelector } from '../../../../store/store'
import { ProjectPhase } from '../../../../types/project'
import { TomFull, TomStatusCountEn, TomStatusTabLabels, tomLabelByTabCountEn } from '../../../../types/tom'
import { filterByFieldNames } from '../../../../utils/filterByFieldNames'
import { UseGetTomsDataProps, UseGetTomsDataResponse } from './useGetTomsData.types'

export const useGetTomsData = ({
  projectId,
  currentTab,
  limitValue,
  offset,
  inProd,
  status,
}: UseGetTomsDataProps): UseGetTomsDataResponse => {
  const { searchValue } = useSearch()
  const formattedSearchValue = searchValue.trim().length ? searchValue.trim() : undefined

  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { filterData } = useTypedSelector(filterDataSelector)

  const {
    selectedObjectsIds,
    selectedRdsIds,
    selectedPdsIds,
    selectedIisIds,
    selectedIrdsIds,
    selectedDocs,
    isExpired,
    iiType,
  } = filterData

  // Rd
  const {
    data: tomsRdData,
    isFetching: isTomsRdFetching,
    isLoading: isTomsRdLoading,
  } = useGetTomsRdFullQuery(
    {
      id: projectId,
      limit: limitValue,
      offset: offset,
      obj: selectedObjectsIds,
      rd: selectedRdsIds,
      pdf: selectedDocs,
      expired: isExpired,
      status: inProd ? 'В производстве' : status,
      search: formattedSearchValue,
    },
    {
      skip: selectedProjectPhase !== 'Рабочая документация',
      refetchOnMountOrArgChange: true,
    },
  )

  const {
    data: tomsRd,
    dataExist: tomsRdExist,
    total: tomsRdTotal,
    statusCount: tomsRdStatusCount,
  } = tomsRdData || ({} as GetTomsRdFullResponse)

  const rdTabsData: TabData<TomStatusTabLabels>[] = useMemo(() => {
    if (!tomsRdStatusCount) {
      return []
    }

    const tabs = Object.keys(tomsRdStatusCount)
      .map((key) => {
        if ((key as TomStatusCountEn) === 'noNeedAgree') return undefined
        return {
          value: tomLabelByTabCountEn[key as TomStatusCountEn],
          label: `${tomLabelByTabCountEn[key as TomStatusCountEn]} (${tomsRdStatusCount[key as TomStatusCountEn]})`,
        }
      })
      .filter((e) => !!e) as TabData<TomStatusTabLabels>[]

    const inProdIndex = tabs.findIndex((tab) => tab.value === tomLabelByTabCountEn.inProd)
    if (inProdIndex !== -1 && inProdIndex !== 1) {
      const [inProdTab] = tabs.splice(inProdIndex, 1)
      tabs.splice(1, 0, inProdTab)
    }

    return tabs
  }, [tomsRdStatusCount])

  // Pd
  const {
    data: tomsPdData,
    isFetching: isTomsPdFetching,
    isLoading: isTomsPdLoading,
  } = useGetTomsPdFullQuery(
    {
      id: projectId,
      limit: limitValue,
      offset: offset,
      pd: selectedPdsIds,
      pdf: selectedDocs,
      expired: isExpired,
      status,
      search: formattedSearchValue,
    },
    {
      skip: selectedProjectPhase !== 'Проектная документация',
      refetchOnMountOrArgChange: true,
    },
  )

  const {
    data: tomsPd,
    dataExist: tomsPdExist,
    total: tomsPdTotal,
    statusCount: tomsPdStatusCount,
  } = tomsPdData || ({} as GetTomsPdFullResponse)

  const pdTabsData: TabData<TomStatusTabLabels>[] = useMemo(() => {
    return tomsPdStatusCount
      ? (Object.keys(tomsPdStatusCount)
          ?.map(
            (key) =>
              (key as TomStatusCountEn) !== 'noNeedAgree' &&
              (key as TomStatusCountEn) !== 'inProd' && {
                value: tomLabelByTabCountEn[key as TomStatusCountEn],
                label: `${tomLabelByTabCountEn[key as TomStatusCountEn]} (${tomsPdStatusCount[key as TomStatusCountEn]})`,
              },
          )
          .filter((e) => !!e) as TabData<TomStatusTabLabels>[])
      : []
  }, [tomsPdStatusCount])

  // Ii
  const {
    data: tomsIiData,
    isFetching: isTomsIiFetching,
    isLoading: isTomsIiLoading,
  } = useGetTomsIiFullQuery(
    {
      id: projectId,
      limit: limitValue,
      offset: offset,
      ii: selectedIisIds,
      type: iiType || undefined,
      pdf: selectedDocs,
      expired: isExpired,
      status,
      search: formattedSearchValue,
    },
    {
      skip: selectedProjectPhase !== 'Инженерные изыскания',
      refetchOnMountOrArgChange: true,
    },
  )

  const {
    data: tomsIi,
    dataExist: tomsIiExist,
    total: tomsIiTotal,
    statusCount: tomsIiStatusCount,
  } = tomsIiData || ({} as GetTomsIiFullResponse)

  const iiTabsData: TabData<TomStatusTabLabels>[] = useMemo(() => {
    return tomsIiStatusCount
      ? (Object.keys(tomsIiStatusCount)
          ?.map(
            (key) =>
              (key as TomStatusCountEn) !== 'noNeedAgree' &&
              (key as TomStatusCountEn) !== 'inProd' && {
                value: tomLabelByTabCountEn[key as TomStatusCountEn],
                label: `${tomLabelByTabCountEn[key as TomStatusCountEn]} (${tomsIiStatusCount[key as TomStatusCountEn]})`,
              },
          )
          .filter((e) => !!e) as TabData<TomStatusTabLabels>[])
      : []
  }, [tomsIiStatusCount])

  // Ird
  const {
    data: tomsIrdData,
    isFetching: isTomsIrdFetching,
    isLoading: isTomsIrdLoading,
  } = useGetTomsIrdFullQuery(
    {
      id: projectId,
      limit: limitValue,
      offset: offset,
      ird: selectedIrdsIds,
      pdf: selectedDocs,
      expired: isExpired,
      status,
      search: formattedSearchValue,
    },
    {
      skip: selectedProjectPhase !== 'Сбор исходных данных',
      refetchOnMountOrArgChange: true,
    },
  )

  const {
    data: tomsIrd = [],
    dataExist: tomsIrdExist = false,
    total: tomsIrdTotal = 0,
    statusCount: tomsIrdStatusCount,
  } = tomsIrdData || {}

  const irdTabsData: TabData<TomStatusTabLabels>[] = useMemo(() => {
    return tomsIrdStatusCount
      ? (Object.keys(tomsIrdStatusCount)
          ?.map(
            (key) =>
              (key as TomStatusCountEn) !== 'inProd' && {
                value: tomLabelByTabCountEn[key as TomStatusCountEn],
                label: `${tomLabelByTabCountEn[key as TomStatusCountEn]} (${tomsIrdStatusCount[key as TomStatusCountEn]})`,
              },
          )
          .filter((e) => !!e) as TabData<TomStatusTabLabels>[])
      : []
  }, [tomsIrdStatusCount])

  // Common
  const tabsData: Record<ProjectPhase, TabData<TomStatusTabLabels>[]> = {
    'Рабочая документация': rdTabsData,
    'Проектная документация': pdTabsData,
    'Инженерные изыскания': iiTabsData,
    'Сбор исходных данных': irdTabsData,
  }

  const dataForRender: Record<ProjectPhase, TomFull[]> = {
    'Рабочая документация': tomsRd,
    'Проектная документация': tomsPd,
    'Инженерные изыскания': tomsIi,
    'Сбор исходных данных': tomsIrd,
  }

  const isTomsExist: Record<ProjectPhase, boolean> = {
    'Рабочая документация': tomsRdExist,
    'Проектная документация': tomsPdExist,
    'Инженерные изыскания': tomsIiExist,
    'Сбор исходных данных': tomsIrdExist,
  }

  const total: Record<ProjectPhase, number> = {
    'Рабочая документация': tomsRdTotal,
    'Проектная документация': tomsPdTotal,
    'Инженерные изыскания': tomsIiTotal,
    'Сбор исходных данных': tomsIrdTotal,
  }

  const isTomsLoading = isTomsRdLoading || isTomsPdLoading || isTomsIiLoading || isTomsIrdLoading
  const isTomsFetching = isTomsRdFetching || isTomsPdFetching || isTomsIiFetching || isTomsIrdFetching

  return {
    tabsData: tabsData[selectedProjectPhase],
    dataForRender: dataForRender[selectedProjectPhase],
    isTomsLoading,
    isTomsFetching,
    isTomsExist: isTomsExist[selectedProjectPhase],
    total: total[selectedProjectPhase],
  }
}
