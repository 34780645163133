import styled from 'styled-components'
import { Tooltip, Typography } from '@mui/material'
import { PocketViewTomCardButtonProps } from './PocketViewTomCardTooltip.types'

export const PocketViewTomCardButtonTooltip = styled(Tooltip)`
`

export const PocketViewTomCardButtonTooltipTitle = styled(Typography)<Pick<PocketViewTomCardButtonProps, 'colorVariant'>>`
  line-height: normal;
  letter-spacing: 0;
  color: ${props => props.colorVariant === 'positive' && props.theme.palette.legends.chartsGreen};
  color: ${props => props.colorVariant === 'negative' && props.theme.palette.error.main};
`

export const PocketViewTomCardButtonTooltipText = styled(Typography)`
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0;
`

export const PocketViewTomCardButtonTooltipSubtitle = styled(PocketViewTomCardButtonTooltipText)`
  color: ${props => props.theme.palette.secondary.gray};
`

