import EditIcon from '@mui/icons-material/Edit'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import { Box, Stack } from '@mui/material'
import { Form, FormikProvider } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { tomSelector } from '@store/slices/documentsPages/tom'

import {
  useCreateInnermapMutation,
  useDeleteInnermapMutation,
  useEditInnermapMutation,
} from '../../../../../api/internalAgreement'
import { InnermapRequest } from '../../../../../api/internalAgreement/api.types'
import { Innermap } from '../../../../../api/internalAgreement/types'
import Tooltip from '../../../../../components/Tooltip'
import { useForm } from '../../../../../hooks/useForm'
import {
  approvedProcedureSelector,
  currentSchemaSelector,
  initialSchemaCompanyFromMenuSelector,
  schemaFormModeSelector,
  setCurrentSchema,
  setInitialSchemaCompanyFromMenu,
  setIsOpenFormAddMember,
  setSchemaFormMode,
} from '../../../../../store/slices/internalAgreement'
import { profileSelector } from '../../../../../store/slices/profile'
import { useAppDispatch, useTypedSelector } from '../../../../../store/store'
import { useAccess } from '../../InternalAgreementSchemes.utils'
import { CancelSchemaButton } from '../CancelSchemaButton/CancelSchemaButton'
import { DeleteSchemaButton } from '../DeleteSchemaButton/DeleteSchemaButton'
import { InternalAgreementPerson } from '../InternalAgreementPerson/InternalAgreementPerson'
import {
  InternalAgreementButton,
  InternalAgreementCheckBox,
  InternalAgreementEditButton,
  InternalAgreementSwitchField,
  InternalAgreementTextField,
  InternalAgreementTimeField,
  InternalAgreementTimeValueField,
  InternalAgreementViewField,
  InternalAgreementWatchDurationField,
  InternalAgreementWatchDurationText,
  StyledForm,
  StyledSpan,
  TitleField,
} from './InternalAgreementControl.styles'
import { IInternalAgreementControlValues } from './InternalAgreementControl.types'
import { validationSchema } from './InternalAgreementControl.utils'

export const InternalAgreementControl = () => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const schemaFormMode = useTypedSelector(schemaFormModeSelector)
  const currentSchema = useTypedSelector(currentSchemaSelector)
  const initialSchemaCompanyFromMenu = useTypedSelector(initialSchemaCompanyFromMenuSelector)
  const approvedProcedures = useTypedSelector(approvedProcedureSelector)
  const profile = useTypedSelector(profileSelector)
  const { tom } = useTypedSelector(tomSelector)

  const isAnnulled = tom?.features.annulment.annulled
  const approved = approvedProcedures.includes(currentSchema?.id)

  const [createSchema, createSchemaResponse] = useCreateInnermapMutation()
  const [editSchema, editSchemaResponse] = useEditInnermapMutation()
  const [deleteSchema, deleteSchemaResponse] = useDeleteInnermapMutation()

  const { isAdmin, isModerator, isExpand, isExpandAcess } = useAccess(projectId)

  const onSubmit = (values: IInternalAgreementControlValues) => {
    const commonData: InnermapRequest = {
      company: values.companyName,
      duration: values.duration || null,
      expandability: values.expandability,
      moderatorId: values.personId,
      publication: values.publication,
      title: values.title,
      unit: values.unit ? 'hour' : 'day',
    }

    schemaFormMode === 'create' && createSchema({ id: projectId, body: commonData })
    schemaFormMode === 'edit' &&
      currentSchema.id &&
      editSchema({
        ...commonData,
        id: projectId,
        innermapId: currentSchema.id,
      })
    dispatch(setInitialSchemaCompanyFromMenu({ initialSchemaCompanyFromMenu: '' }))
  }

  const unitValue = currentSchema?.unit === 'hour' ? 'ч' : 'дн'

  const initialValues: IInternalAgreementControlValues = {
    companyName: schemaFormMode === 'create' ? initialSchemaCompanyFromMenu : currentSchema?.company,
    duration: schemaFormMode === 'edit' ? currentSchema.duration : null,
    expandability: schemaFormMode === 'edit' ? currentSchema.expandability : false,
    publication: schemaFormMode === 'edit' ? currentSchema.publication : false,
    personId:
      schemaFormMode === 'edit'
        ? currentSchema.moderator.id
        : schemaFormMode === 'create' && !isAdmin
          ? profile.employeeId
          : null,
    title: schemaFormMode === 'edit' ? currentSchema.title : '',
    unit: schemaFormMode === 'edit' ? currentSchema.unit === 'hour' && true : false,
  }

  const { formik } = useForm({
    validationSchema,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })
  const onFormModeEditClick = () => dispatch(setSchemaFormMode({ schemaFormMode: 'edit' }))
  const onCancelFormModeEditClick = () => dispatch(setSchemaFormMode({ schemaFormMode: 'view' }))
  const openFormAddMember = () =>
    dispatch(setIsOpenFormAddMember({ isOpenFormAddMember: { isOpen: true, formMode: 'create' } }))
  const confirmDeleteSchema = () => deleteSchema({ id: projectId, innermapId: currentSchema.id })

  const { values, isValid, isSubmitting, dirty } = formik

  useEffect(() => {
    if (createSchemaResponse.data) {
      dispatch(setSchemaFormMode({ schemaFormMode: 'view' }))
      dispatch(setCurrentSchema(createSchemaResponse.data))
    }
  }, [createSchemaResponse.data])

  useEffect(() => {
    if (editSchemaResponse.data) {
      dispatch(setSchemaFormMode({ schemaFormMode: 'view' }))
      dispatch(setCurrentSchema(editSchemaResponse.data))
    }
  }, [editSchemaResponse.data])

  useEffect(() => {
    if (deleteSchemaResponse.data) {
      dispatch(setCurrentSchema({} as Innermap))
      dispatch(setSchemaFormMode({ schemaFormMode: 'view' }))
      enqueueSnackbar('Схема успешно удалена', { variant: 'success' })
    }
    if (deleteSchemaResponse.isError) {
      dispatch(setCurrentSchema({} as Innermap))
      dispatch(setSchemaFormMode({ schemaFormMode: 'view' }))
      enqueueSnackbar('Схема не удалена, попробуйте еще раз', { variant: 'error' })
    }
  }, [deleteSchemaResponse])

  return (
    <FormikProvider value={formik}>
      <StyledForm>
        <Stack direction='row' alignItems='center'>
          <Stack mr='16px' direction='row' alignItems='center' width='100%' spacing={1}>
            <TitleField>Схема</TitleField>
            {schemaFormMode === 'view' ? (
              <Tooltip title={currentSchema?.title && currentSchema?.title.length > 23 ? currentSchema?.title : ''}>
                <InternalAgreementViewField variant='body2'>{currentSchema?.title}</InternalAgreementViewField>
              </Tooltip>
            ) : (
              <InternalAgreementTextField name='title' placeholder='Укажите название' version='project' />
            )}
          </Stack>

          <InternalAgreementPerson
            mode={schemaFormMode}
            person={currentSchema?.moderator}
            autocompleteHeight='36px'
            withAvatar={true}
          />
          {schemaFormMode === 'view' && !currentSchema?.duration ? null : (
            <Stack mr='16px' direction='row' alignItems='center' width='100%' spacing={1}>
              <TitleField>Срок</TitleField>
              {schemaFormMode === 'create' || schemaFormMode === 'edit' ? (
                <InternalAgreementTimeField direction='row' spacing={0}>
                  <InternalAgreementTimeValueField
                    inputProps={{ variant: 'standart' }}
                    name='duration'
                    placeholder='00'
                    type='number'
                    version='project'
                  />
                  <InternalAgreementSwitchField
                    name='unit'
                    version='switch'
                    checkboxData={{
                      label: null,
                      checked: false,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        formik.setFieldValue('unit', e.target.checked)
                      },
                    }}
                  />
                  <StyledSpan $checked={values.unit ? false : true}>ДНИ</StyledSpan>
                  <StyledSpan $checked={values.unit ? true : false}>ЧАС</StyledSpan>
                </InternalAgreementTimeField>
              ) : (
                <InternalAgreementWatchDurationField isBroken={false /* !!currentSchema.isBroken */} direction='row'>
                  {/*                   {currentSchema.isBroken
                    ? <WarningAmberIcon fontSize='small' color='warning' />
                    : <WatchLaterIcon fontSize='small' className='clockIcon' />} */}
                  <WatchLaterIcon fontSize='small' className='clockIcon' />
                  <InternalAgreementWatchDurationText variant='body2'>
                    {currentSchema.duration || 0} {unitValue}
                  </InternalAgreementWatchDurationText>
                </InternalAgreementWatchDurationField>
              )}
            </Stack>
          )}
          {currentSchema?.expandability && schemaFormMode === 'view' && (
            <Tooltip title={<>Участникам внутреннего согласования разрешено дополнять схему</>}>
              <TitleField mr={2}>Может дополняться</TitleField>
            </Tooltip>
          )}
          {(schemaFormMode === 'create' || schemaFormMode === 'edit') && (
            <Tooltip title={<>Разрешить участникам внутреннего согласования дополнять схему</>}>
              <Stack direction='row' justifyContent='center' alignItems='center' width='100%' spacing={2}>
                <TitleField>Дополнять</TitleField>
                <InternalAgreementCheckBox
                  name='expandability'
                  version='checkbox'
                  InputProps={{ defaultChecked: currentSchema?.expandability }}
                  checkboxData={{
                    label: null,
                    checked: values.expandability,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue('expandability', e.target.checked)
                    },
                  }}
                />
              </Stack>
            </Tooltip>
          )}

          {currentSchema?.publication && schemaFormMode === 'view' && (
            <Tooltip title={<>Том будет опубликован для компании после утверждения</>}>
              <TitleField>Публикация</TitleField>
            </Tooltip>
          )}
          {(schemaFormMode === 'create' || schemaFormMode === 'edit') && (
            <Tooltip title={<>Опубликовать том для компании после утверждения</>}>
              <Stack direction='row' justifyContent='center' alignItems='center' width='100%' spacing={2}>
                <TitleField>Публикация</TitleField>
                <InternalAgreementCheckBox
                  name='publication'
                  version='checkbox'
                  InputProps={{ defaultChecked: currentSchema?.publication }}
                  checkboxData={{
                    label: null,
                    checked: values.publication,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue('publication', e.target.checked)
                    },
                  }}
                />
              </Stack>
            </Tooltip>
          )}

          {schemaFormMode === 'view' && !isAnnulled && (isAdmin || isModerator) && !approved && (
            <Box>
              <InternalAgreementEditButton
                onClick={onFormModeEditClick}
                variant='text'
                startIcon={<EditIcon fontSize='small' />}
              >
                Изменить
              </InternalAgreementEditButton>
            </Box>
          )}
        </Stack>
        {schemaFormMode === 'create' && (
          <InternalAgreementButton color='success' type='submit' disabled={!isValid || isSubmitting || !dirty}>
            Создать
          </InternalAgreementButton>
        )}
        {schemaFormMode === 'view' &&
          !isAnnulled &&
          (isAdmin || isModerator || (isExpand && isExpandAcess)) &&
          !approved && (
            <InternalAgreementButton onClick={openFormAddMember}>Добавить участника</InternalAgreementButton>
          )}
        {schemaFormMode === 'edit' && (
          <Stack direction='row' spacing={1}>
            <InternalAgreementButton color='success' type='submit' disabled={!(isValid || dirty)}>
              Сохранить
            </InternalAgreementButton>
            <CancelSchemaButton confirmCancelEditSchema={onCancelFormModeEditClick} values={values} />
            {schemaFormMode === 'edit' && (
              <DeleteSchemaButton confirmDeleteSchema={confirmDeleteSchema} schemaName={currentSchema?.title} />
            )}
          </Stack>
        )}
      </StyledForm>
    </FormikProvider>
  )
}
