import qs from 'qs'

import { api } from '../api'
import {
  CreateProjectRequest,
  DeleteImagesFromProjectRequest,
  DeleteProjectRequest,
  EditProjectRequest,
  EditProjectStageRequest,
  GetAgreementSchemesCountRequest,
  GetAgreementSchemesCountResponse,
  GetProjectArchitectorsResponse,
  GetProjectByIdRequest,
  GetProjectByIdResponse,
  GetProjectClientsResponse,
  GetProjectDashboardRequest,
  GetProjectDashboardResponse,
  GetProjectRegionsResponse,
  GetProjectsOverallRequest,
  GetProjectsOverallResponse,
  GetProjectsRequest,
  GetProjectsResponse,
  GetTimWidgetRequest,
  GetTimWidgetResponse,
  GetTomsFileListRequest,
  GetTomsFileListResponse,
  GetWorksWidgetRequest,
  GetWorksWidgetResponse,
  IGetWidgetSettingsRequest,
  IGetWidgetSettingsResponse,
  IUpdateWidgetSettingsRequest,
  IUpdateWidgetSettingsResponse,
  ProjectBaseResponse,
  ProjectBaseWithImagesResponse,
  SendTomsFileListRequest,
  SendTomsFileListResponse,
  UploadImagesToProjectRequest,
} from './api.types'

export const projectsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProjects: build.query<GetProjectsResponse, GetProjectsRequest>({
      query: ({ limit, offset }) => ({
        url: '/project/list',
        params: { limit, offset },
        method: 'GET',
      }),
      providesTags: ['Projects'],
    }),
    getProjectsOverall: build.query<GetProjectsOverallResponse, GetProjectsOverallRequest>({
      query: (params) => ({
        url: '/project/overall',
        params,
        method: 'GET',
      }),
      transformResponse: (response: GetProjectsOverallResponse): GetProjectsOverallResponse => {
        const { supervision, ...rest } = response.statusCount

        return { ...response, statusCount: rest }
      },
      providesTags: ['Projects'],
    }),
    createProject: build.mutation<ProjectBaseResponse, CreateProjectRequest>({
      query: (body) => ({
        url: `/project/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Projects'],
    }),
    getProjectById: build.query<GetProjectByIdResponse, GetProjectByIdRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/get`,
        method: 'GET',
      }),
      providesTags: ['Projects'],
    }),
    getProjectDashboard: build.query<GetProjectDashboardResponse, GetProjectDashboardRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/dashboard`,
        method: 'GET',
      }),
      providesTags: ['Projects'],
    }),
    getAgreementSchemesCount: build.query<GetAgreementSchemesCountResponse, GetAgreementSchemesCountRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/dashboard/widgets/approval-maps`,
        method: 'GET',
      }),
      providesTags: ['InternalAgreement', 'Agreement'],
    }),
    editProject: build.mutation<ProjectBaseResponse, EditProjectRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/update`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Projects'],
    }),
    editProjectStage: build.mutation<ProjectBaseResponse, EditProjectStageRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/update-status`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Projects'],
    }),
    deleteProject: build.mutation<ProjectBaseWithImagesResponse, DeleteProjectRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Projects'],
    }),
    uploadImagesToProject: build.mutation<ProjectBaseWithImagesResponse, UploadImagesToProjectRequest>({
      query: ({ id, file }) => {
        const formData = new FormData()
        file.forEach((file) => {
          formData.append('file', file)
        })

        return {
          url: `/project/${id}/images/upload`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: ['Projects'],
    }),
    deleteImagesFromProject: build.mutation<ProjectBaseWithImagesResponse, DeleteImagesFromProjectRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/images/delete?${qs.stringify(params, { indices: false })}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Projects'],
    }),
    getTomsFileList: build.query<GetTomsFileListResponse, GetTomsFileListRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/download/get-file-list`,
        method: 'GET',
        params,
      }),
    }),
    sendTomsFileList: build.mutation<SendTomsFileListResponse, SendTomsFileListRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/download/create-download-link`,
        method: 'POST',
        body,
      }),
    }),
    getProjectClients: build.query<GetProjectClientsResponse, void>({
      query: () => ({
        url: `/project/filter/clients`,
        method: 'GET',
      }),
    }),
    getProjectArchitectors: build.query<GetProjectArchitectorsResponse, void>({
      query: () => ({
        url: `/project/filter/architectors`,
        method: 'GET',
      }),
    }),
    getProjectRegions: build.query<GetProjectRegionsResponse, void>({
      query: () => ({
        url: `/project/filter/regions`,
        method: 'GET',
      }),
    }),
    getWorksWidget: build.query<GetWorksWidgetResponse, GetWorksWidgetRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/dashboard/widgets/works-total`,
        method: 'GET',
      }),
      providesTags: ['Schedules'],
    }),
    getTimWidget: build.query<GetTimWidgetResponse, GetTimWidgetRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/dashboard/widgets/tim`,
        method: 'GET',
      }),
      providesTags: ['Tim', { type: 'Tim', id: 'WIDGET' }],
    }),
    getWidgetSettings: build.query<IGetWidgetSettingsResponse, IGetWidgetSettingsRequest>({
      query: ({ projectId }) => ({
        url: `/settings/user/projects/${projectId}/widgets`,
        method: 'GET',
      }),
      providesTags: ['DashboardWidgets'],
    }),
    updateWidgetSettings: build.mutation<IUpdateWidgetSettingsResponse, IUpdateWidgetSettingsRequest>({
      query: ({ projectId, body }) => ({
        url: `/settings/user/projects/${projectId}/widgets`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['DashboardWidgets'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetProjectsQuery,
  useGetProjectsOverallQuery,
  useCreateProjectMutation,
  useGetProjectByIdQuery,
  useGetProjectDashboardQuery,
  useGetAgreementSchemesCountQuery,
  useEditProjectMutation,
  useEditProjectStageMutation,
  useDeleteProjectMutation,
  useUploadImagesToProjectMutation,
  useDeleteImagesFromProjectMutation,
  useGetTomsFileListQuery,
  useSendTomsFileListMutation,
  useGetProjectClientsQuery,
  useGetProjectArchitectorsQuery,
  useGetProjectRegionsQuery,
  useGetWorksWidgetQuery,
  useGetTimWidgetQuery,
  useGetWidgetSettingsQuery,
  useUpdateWidgetSettingsMutation,
} = projectsApi
