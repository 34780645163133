import { api } from '../../api'
import {
  CreateObjectRequest,
  DeleteObjectRequest,
  EditObjectRequest,
  ExportObjectsRequest,
  GetObjectByIdRequest,
  GetObjectLinkExampleResponse,
  GetObjectsRequest,
  GetObjectsResponse,
  ObjectBaseResponse,
  UploadObjectRequest
} from './objectsApi.types'
import { ExcelUploadResponse } from '../../../types/global'

export const objectsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getObjectsByProjectId: build.query<GetObjectsResponse, GetObjectsRequest>({
      query: ({ limit, offset, id }) => ({
        url: `/project/${id}/object/list`,
        params: { limit, offset },
        method: 'GET',
      }),
      providesTags: ['ObjectsByProject', 'ObjectsByProjectUpload'],
    }),
    createObject: build.mutation<ObjectBaseResponse, CreateObjectRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/object/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'ObjectsByProject',
        { type: 'Tim', id: 'SIDE_MENU' },
        { type: 'TomRd', id: 'UPLOAD' },
        { type: 'Tim', id: 'WIDGET' }],
    }),
    uploadObject: build.mutation<ExcelUploadResponse, UploadObjectRequest>({
      query: ({ id, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/${id}/object/upload`,
            method: 'POST',
            body: formData,
          }
        }
      },
      invalidatesTags: [
        'ObjectsByProjectUpload',
        { type: 'Tim', id: 'SIDE_MENU' },
        { type: 'TomRd', id: 'UPLOAD' },
        { type: 'Tim', id: 'WIDGET' }],
    }),
    exportObjects: build.mutation<Blob, ExportObjectsRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/object/export-excel-file`,
        method: 'POST',
        responseHandler: async (response: any) => await response.blob(),
      }),
    }),
    getObjectById: build.query<ObjectBaseResponse, GetObjectByIdRequest>({
      query: ({ id }) => ({
        url: `/project/object/${id}/get`,
        method: 'GET',
      }),
      providesTags: ['ObjectsByProject'],
    }),
    editObject: build.mutation<ObjectBaseResponse, EditObjectRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/object/${id}/update`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ id, projectId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedObject } = await queryFulfilled

          dispatch(
            objectsApi.util.updateQueryData('getObjectsByProjectId', { id: projectId }, (draft) => {
              const changedObject = draft.data.findIndex(object => object.id === updatedObject.data.id)
              draft.data.splice(changedObject, 1, updatedObject.data)
            })
          )

          dispatch(
            objectsApi.util.updateQueryData('getObjectById', { id }, (draft) => {
              Object.assign(draft, updatedObject)
            })
          )
        } catch {
        }
      },
      invalidatesTags: [{ type: 'Tim', id: 'SIDE_MENU' }, { type: 'TomRd', id: 'UPLOAD' }],
    }),
    deleteObject: build.mutation<ObjectBaseResponse, DeleteObjectRequest>({
      query: ({ id }) => ({
        url: `/project/object/${id}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'ObjectsByProject',
        { type: 'Tim', id: 'SIDE_MENU' },
        { type: 'TomRd', id: 'DELETE' },
        { type: 'Tim', id: 'WIDGET' }],
    }),
    getObjectLinkExample: build.mutation<GetObjectLinkExampleResponse, void>({
      query: () => ({
        url: `/project/object/link-example`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetObjectsByProjectIdQuery,
  useCreateObjectMutation,
  useUploadObjectMutation,
  useExportObjectsMutation,
  useGetObjectByIdQuery,
  useEditObjectMutation,
  useDeleteObjectMutation,
  useGetObjectLinkExampleMutation
} = objectsApi
