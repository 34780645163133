import { Stack } from "@mui/material"
import { ModelViewDrawer } from "./components/ModelViewDrawer"
import { InfoPage } from "./components/InfoPage"
import { useEffect, useMemo, useState } from "react"
import { TanglViewer } from "./components/TanglViewer"
import { useAppDispatch, useTypedSelector } from "store/store"
import { setSelectedModels } from "store/slices/tim/tim"
import { useGetTanglIntegrationStatusQuery, useGetTanglTokenQuery } from "api/settings"
import { useNavigate } from "react-router-dom"
import Progress from "components/Progress"
import { ModelMetaInfo } from "./components/ModelMetaInfo"
import { openedDrawerSelector } from "store/slices/documentsPages/drawerInfo"

export const ModelView = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const [modelIds, setModelIds] = useState<string[]>([])
  const [isError, setIsError] = useState<boolean>(false)
  const [isLoadingModel, setIsLoadingModel] = useState<boolean>(false)
  const [showMetaInfo, setShowMetaInfo] = useState<boolean>(false)
  const { data: tokenResponse } = useGetTanglTokenQuery()
  const { token } = tokenResponse || {}

  const { data: statusIntegrationData } = useGetTanglIntegrationStatusQuery()
  const { status: statusIntegration } = statusIntegrationData || {}

  const onSelectIds = (ids: string[]) => {
    isError && setIsError(false)
    setModelIds(ids)
    setIsLoadingModel(true)
  }

  const viewerWidth = useMemo((): string => {
    if (openedDrawer === 'timView' && showMetaInfo) {
      return 'calc(100% - 720px)'
    } else if (openedDrawer === 'timView' || showMetaInfo) {
      return 'calc(100% - 360px)'
    } else return '100%'
  }, [openedDrawer, showMetaInfo])

  const onError = () => {
    setIsLoadingModel(false)
    setShowMetaInfo(false)
    setIsError(true)
  }

  const onToggleClick = () => {
    setShowMetaInfo(prev => !prev)
  }

  const toRetry = () => {
    setIsError(false)
  }

  const setIsLoadingComplete = () => {
    setIsLoadingModel(false)
  }

  useEffect(() => {
    return (() => {
      dispatch(setSelectedModels([]))
    })
  }, [])

  if (statusIntegration === 'ENABLED') {
    return (
      <Stack direction='row' width='100%'>
        <ModelViewDrawer
          showMetaInfo={showMetaInfo}
          onToggleClick={onToggleClick}
          selectIds={onSelectIds}
          onError={onError}
          isLoadingModel={isLoadingModel}
        />
        <Stack width={viewerWidth} position='relative' mt={1}>
          {!modelIds.length ?
            <InfoPage variant='info' />
            : !isError ?
              <TanglViewer
                tokenData={token?.access_token}
                modelId={modelIds}
                onError={onError}
                isLoadingComplete={setIsLoadingComplete} />
              : <InfoPage variant='error' callBackClick={toRetry} />
          }
        </Stack>
         {!isError && <ModelMetaInfo opened={showMetaInfo} onClose={onToggleClick}/>}
      </Stack>
    )
  } else if (statusIntegration === undefined) {
    return <Progress />
  } else {
    navigate(-1)
    return null
  }

}