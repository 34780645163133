import React, { FC, useEffect, useMemo, useState } from 'react'
import { FormPocketDrawerFormData, FormPocketDrawerFormProps, } from './FormPocketDrawerForm.types'
import { FormPocketDrawerFormWrapper } from './FormPocketDrawerForm.styles'
import { Form, FormikProvider } from 'formik'
import { Button, Stack } from '@mui/material'
import { useForm } from '../../../../../../hooks/useForm'
import { FormPocketDrawerMainList } from '../FormPocketDrawerMainList'
import { FormPocketDrawerSelectedList } from '../FormPocketDrawerSelectedList'
import { pocketDrawerFormValidation } from './FormPocketDrawerForm.validation'
import { useSnackbar } from 'notistack'
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers'
import { useParams } from 'react-router-dom'
import { agreementPhaseByProjectPhase, RoadmapPhase } from '../../../../../../api/projectAgreement/types'
import { useCreateAgreementPocketMutation, useGetAgreementPocketMenuQuery } from '../../../../../../api/agreementPocket'
import { CreateAgreementPocketRequest } from '../../../../../../api/agreementPocket/api.types'

export const FormPocketDrawerForm: FC<FormPocketDrawerFormProps> = ({
  onFormChange,
  onDrawerClose,
  initialPhase,
  showPhaseSwitcher,
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const [selectedPhase, setSelectedPhase] = useState<RoadmapPhase>(initialPhase ? agreementPhaseByProjectPhase[initialPhase] : 'ИИ')
  const onPhaseChange = (phase: RoadmapPhase) => {
    setSelectedPhase(phase)
  }

  const { data: agreementPocketMenuData } = useGetAgreementPocketMenuQuery(
    { projectId, phase: selectedPhase },
    { skip: !projectId || !selectedPhase }
  )
  const { data, roadmaps = [] } = agreementPocketMenuData || {}
  const { groups = [], objects = [] } = data || {}

  const initialValues: FormPocketDrawerFormData = useMemo(() => ({
    objectList: objects,
    groupList: groups,
    selectedToms: [],
    title: '',
    roadmapId: 0,
  }), [data])


  const [createAgreementPocket, createAgreementPocketResponse] = useCreateAgreementPocketMutation()

  const onSubmit = (values: FormPocketDrawerFormData) => {
    const dataForRequest: CreateAgreementPocketRequest = {
      projectId,
      roadmapId: values.roadmapId,
      title: values.title,
      toms: values.selectedToms.map(({ tom }) => tom.id),
    }

    createAgreementPocket(dataForRequest)
  }

  useMutationHandlers(
    createAgreementPocketResponse,
    (data) => {
      if (data) {
        enqueueSnackbar('Накладная успешно отправлена.', { variant: 'success' })
        onDrawerClose(false, true)
      } else {
        enqueueSnackbar('Не удалось отправить накладную.', { variant: 'error' })
      }
    },
  )

  const { formik } = useForm({
    validationSchema: pocketDrawerFormValidation,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, errors, dirty, isSubmitting, isValid, touched } = formik

  const minTomsCountError = errors.selectedToms

  const isDirty = useMemo(() => {
    return !!(values.selectedToms.length || values.title || values.roadmapId)
  }, [values])

  useEffect(() => {
    if (isSubmitting && minTomsCountError) {
      enqueueSnackbar('Выберите минимум один том', { variant: 'error' })
    }
  }, [isSubmitting, errors])

  useEffect(() => {
    onFormChange(isDirty)
  }, [isDirty])

  return (
    <FormPocketDrawerFormWrapper>
      <FormikProvider value={formik}>
        <Stack spacing={2.5} component={Form} justifyContent='space-between' height='100%'>
          <Stack direction='row' justifyContent='space-between' spacing={2.5} overflow='hidden' height='100%'>
            <FormPocketDrawerMainList
              selectedPhase={selectedPhase}
              onPhaseChange={onPhaseChange}
              showPhaseSwitcher={showPhaseSwitcher}
            />
            <FormPocketDrawerSelectedList roadmaps={roadmaps} />
          </Stack>


          <Stack direction='row' justifyContent='end' alignItems='center' spacing={2}>
            <Stack direction='row' spacing={2}>
              <Button
                type='submit'
                disabled={
                  !(touched?.title && isValid)
                  || isSubmitting
                }
                color='success'
                size='small'
              >
                Сформировать
              </Button>

              <Button
                size='small'
                onClick={() => onDrawerClose(isDirty)}
              >
                Отменить и закрыть
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </FormikProvider>
    </FormPocketDrawerFormWrapper>
  )
}
