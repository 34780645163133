import React from 'react'
import { LayoutAccessData, UseGetTomProps, UseGetTomResponse } from './useGetTom.types'
import { ProjectPhase } from '../../../../types/project'
import { useGetTomRdByIdQuery } from '../../../../api/rdPhase'
import { useGetTomPdByIdQuery } from '../../../../api/pdPhase'
import { useGetTomIiByIdQuery } from '../../../../api/iiPhase'
import { Tom } from '../../../../types/tom'
import { useTypedSelector } from '../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../store/slices/documentsPages/projectInfo'
import _ from 'lodash'
import { useGetTomIrdByIdQuery } from '../../../../api/irdPhase'

export const useGetTom = ({
  project,
  tomId,
  rdAccess = false,
  pdAccess = false,
  iiAccess = false,
  irdAccess = false,
}: UseGetTomProps): UseGetTomResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector, _.isEqual)

  // Rd
  const { data: tomRdData, isFetching: tomRdFetching } = useGetTomRdByIdQuery({
    id: tomId!
  }, { skip: !tomId || selectedProjectPhase !== 'Рабочая документация' })
  const { data: currentRdTom, roadmapAccess: roadmapAccessRdTom } = tomRdData || {}


  // Pd
  const { data: tomPdData, isFetching: tomPdFetching } = useGetTomPdByIdQuery({
    id: tomId!
  }, { skip: !tomId || selectedProjectPhase !== 'Проектная документация' })
  const { data: currentPdTom, roadmapAccess: roadmapAccessPdTom } = tomPdData || {}

  // Ii
  const { data: tomIiData, isFetching: tomIiFetching } = useGetTomIiByIdQuery({
    id: tomId!
  }, { skip: !tomId || selectedProjectPhase !== 'Инженерные изыскания' })
  const { data: currentIiTom, roadmapAccess: roadmapAccessIiTom } = tomIiData || {}

  // Ird
  const { data: tomIrdData, isFetching: tomIrdFetching } = useGetTomIrdByIdQuery({
    id: tomId!
  }, { skip: !tomId || selectedProjectPhase !== 'Сбор исходных данных' })
  const { data: currentIrdTom, roadmapAccess: roadmapAccessIrdTom } = tomIrdData || {}


  // Common
  const currentTom: Record<ProjectPhase, Tom> = {
    'Рабочая документация': currentRdTom!,
    'Проектная документация': currentPdTom!,
    'Инженерные изыскания': currentIiTom!,
    'Сбор исходных данных': currentIrdTom!,
  }

  const roadmapAccess = {
    'Рабочая документация': roadmapAccessRdTom!,
    'Проектная документация': roadmapAccessPdTom!,
    'Инженерные изыскания': roadmapAccessIiTom!,
    'Сбор исходных данных': roadmapAccessIrdTom!,
  }

  const tomLoading =
    tomRdFetching
    || tomPdFetching
    || tomIiFetching
    || tomIrdFetching

  const accessData: LayoutAccessData = {
    rdAccess,
    pdAccess,
    iiAccess,
    irdAccess,
  }

  return {
    currentTom: currentTom[selectedProjectPhase],
    roadmapAccess: roadmapAccess[selectedProjectPhase],
    tomLoading,
    accessData,
  }
}
