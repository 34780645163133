import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Stack, Typography } from '@mui/material'
import { FC, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import SmallDownloadIcon from '../../../../assets/icons/SmallDownloadIcon'
import { setOpenedDrawer } from '../../../../store/slices/documentsPages/drawerInfo'
import { FilterData, clearFilter, setFilterData } from '../../../../store/slices/documentsPages/filter'
import { setTimFilter, setTimStatus } from '../../../../store/slices/tim/tim'
import { useAppDispatch } from '../../../../store/store'
import { InfoCardMediumVariant } from '../InfoCard'
import { InfoBlockWrapper, InfoCardButton, StyledButton } from './PhaseCardContent.styles'
import { PhaseCardContentProps } from './PhaseCardContent.types'
import { useGetPhaseInfo } from './hooks/useGetPhaseInfo'

export const PhaseCardContent: FC<PhaseCardContentProps> = ({
  variant,
  statRd,
  statPd,
  statIi,
  statIrd,
  statTim,
  showTask = false,
  disableExport,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { statData, toProd, setProjectPhase, emptyFilterData } = useGetPhaseInfo({
    variant,
    statRd,
    statPd,
    statIi,
    statIrd,
    statTim,
  })
  const { empty, toWork, toAgreed, onCorrect, expired } = statData || {}

  const timNavigate = () => {
    navigate(`tim/models`)
  }

  const onEmptyClick = (e: MouseEvent<HTMLButtonElement>, variant: InfoCardMediumVariant) => {
    e.stopPropagation()
    if (variant === 'tim') {
      dispatch(setTimFilter({ timFilter: { objectFiltered: null, emptyFilter: true, allTims: true } }))
      timNavigate()
      //navigate(`tim/models?status=empty`)
      return
    }
    localStorage.removeItem('docsInitialTab')
    setProjectPhase()
    const filterData: FilterData = {
      ...emptyFilterData,
      selectedDocs: ['none'],
    }
    dispatch(setFilterData({ filterData }))
    navigate(`toms`)
  }

  const onExpiredClick = (e: MouseEvent<HTMLButtonElement>, variant: InfoCardMediumVariant) => {
    e.stopPropagation()
    if (variant === 'tim') {
      dispatch(setTimFilter({ timFilter: { objectFiltered: null, expiredFilter: true, allTims: true } }))
      timNavigate()
      return
    }
    localStorage.removeItem('docsInitialTab')
    setProjectPhase()
    const filterData: FilterData = {
      ...emptyFilterData,
      isExpired: true,
    }
    dispatch(setFilterData({ filterData }))
    navigate(`toms`)
  }

  const baseRedirect = (e: MouseEvent<HTMLButtonElement>, variant: InfoCardMediumVariant) => {
    e.stopPropagation()
    setProjectPhase()
    dispatch(clearFilter({}))
    navigate(`toms`)
  }

  const onToWorkClick = (e: MouseEvent<HTMLButtonElement>, variant: InfoCardMediumVariant) => {
    e.stopPropagation()
    if (variant === 'tim') {
      dispatch(setTimStatus({ timStatus: 'Не в работе' }))
      dispatch(setTimFilter({ timFilter: { objectFiltered: null, allTims: true } }))
      timNavigate()
      return
    }
    localStorage.setItem('docsInitialTab', 'Не в работе')
    baseRedirect(e, variant)
  }

  const onToAgreedClick = (e: MouseEvent<HTMLButtonElement>, variant: InfoCardMediumVariant) => {
    e.stopPropagation()
    if (variant === 'tim') {
      dispatch(setTimStatus({ timStatus: 'На согласовании' }))
      dispatch(setTimFilter({ timFilter: { objectFiltered: null, allTims: true } }))
      timNavigate()
      return
    }
    localStorage.setItem('docsInitialTab', 'На согласовании')
    baseRedirect(e, variant)
  }

  const onCorrectClick = (e: MouseEvent<HTMLButtonElement>, variant: InfoCardMediumVariant) => {
    e.stopPropagation()
    if (variant === 'tim') {
      dispatch(setTimStatus({ timStatus: 'Корректировка' }))
      dispatch(setTimFilter({ timFilter: { objectFiltered: null, allTims: true } }))
      timNavigate()
      return
    }
    localStorage.setItem('docsInitialTab', 'Корректировка')
    baseRedirect(e, variant)
  }

  const onToProdClick = (e: MouseEvent<HTMLButtonElement>, variant: InfoCardMediumVariant) => {
    localStorage.setItem('docsInitialTab', 'Согласовано')
    baseRedirect(e, variant)
  }

  const onArchiveClick = (e: MouseEvent<HTMLButtonElement>, variant: InfoCardMediumVariant) => {
    e.stopPropagation()
    setProjectPhase()
    localStorage.setItem('initialDrawerOpened', 'exportArchive')
    navigate(`toms`)
  }

  const onAgreementClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setProjectPhase()
    dispatch(setOpenedDrawer({ openedDrawer: 'formPocket' }))
  }

  const onCmnClick = (e: MouseEvent<HTMLButtonElement>, variant: InfoCardMediumVariant) => {
    e.stopPropagation()
    setProjectPhase()
    navigate(`tom/cmn`)
  }

  return (
    <Stack flex={1} justifyContent='space-between' spacing={1.5}>
      <Stack spacing={1.5}>
        <InfoBlockWrapper>
          <InfoCardButton
            variant='text'
            onClick={(e) => onEmptyClick(e, variant)}
            disabled={!empty}
            endIcon={<Typography variant='body2'>{empty}</Typography>}
          >
            {variant !== 'tim' ? 'Пустые тома' : 'Пустые модели'}
          </InfoCardButton>

          <InfoCardButton
            variant='text'
            onClick={(e) => onToWorkClick(e, variant)}
            disabled={!toWork}
            endIcon={<Typography variant='body2'>{toWork}</Typography>}
          >
            {variant !== 'tim' ? 'Тома не в работе' : 'Модели не в работе'}
          </InfoCardButton>

          <InfoCardButton
            variant='text'
            onClick={(e) => onToAgreedClick(e, variant)}
            disabled={!toAgreed}
            endIcon={<Typography variant='body2'>{toAgreed}</Typography>}
          >
            Ожидают согласования
          </InfoCardButton>

          <InfoCardButton
            variant='text'
            onClick={(e) => onCorrectClick(e, variant)}
            disabled={!onCorrect}
            endIcon={<Typography variant='body2'>{onCorrect}</Typography>}
          >
            Корректировка
          </InfoCardButton>

          <InfoCardButton
            variant='text'
            onClick={(e) => onExpiredClick(e, variant)}
            disabled={!expired}
            endIcon={<Typography variant='body2'>{expired}</Typography>}
          >
            Просрочено
          </InfoCardButton>

          {variant === 'rd' && (
            <InfoCardButton
              variant='text'
              onClick={(e) => onToProdClick(e, variant)}
              disabled={!toProd}
              endIcon={<Typography variant='body2'>{toProd}</Typography>}
            >
              Не в производстве
            </InfoCardButton>
          )}
        </InfoBlockWrapper>

        {variant !== 'tim' && (
          <InfoBlockWrapper>
            {/*         {variant === 'pd' && (
          <InfoCardButton
            variant='text'
            onClick={(e) => onCmnClick(e, variant)}
            startIcon={<EditDocIcon fontSize='small' />}
          >
            Сопоставление ПД и РД
          </InfoCardButton>
        )} */}

            {/*         {showTask && (
          <InfoCardButton
            variant='text'
            onClick={() => {
            }}
            disabled
            startIcon={<DocTIcon fontSize='small' />}
          >
            Техническое задание
          </InfoCardButton>
        )} */}

            <InfoCardButton
              disabled={disableExport}
              variant='text'
              onClick={(e) => onArchiveClick(e, variant)}
              startIcon={<SmallDownloadIcon fontSize='small' />}
            >
              Экспортировать док-ты
            </InfoCardButton>
          </InfoBlockWrapper>
        )}
      </Stack>
      {variant !== 'ird' && variant !== 'tim' && (
        <StyledButton color='secondary' size='thin' onClick={(e) => onAgreementClick(e)}>
          Согласование документации
        </StyledButton>
      )}
    </Stack>
  )
}
