import { FC, SyntheticEvent } from 'react'
import { PocketViewTomCardTooltipProps, tooltipStyle } from './PocketViewTomCardTooltip.types'
import {
  PocketViewTomCardButtonTooltip,
  PocketViewTomCardButtonTooltipSubtitle,
  PocketViewTomCardButtonTooltipText,
  PocketViewTomCardButtonTooltipTitle
} from './PocketViewTomCardTooltip.styles'
import { isIOS, isMobile } from '../../../../utils/isIOS'
import { theme } from '../../../../styles/theme'
import { Stack } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { getWordWithEnding } from '../../../../utils/getWordWithEnding'

export const PocketViewTomCardTooltip: FC<PocketViewTomCardTooltipProps> = ({
  result,
  colorVariant,
  children,
  ...baseProps
}) => {
  const { title, tomStatus, nextStage } = result

  const preventOnClickOnMobileIos = (e: SyntheticEvent) => {
    if (isIOS() && isMobile()) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  return (
    <PocketViewTomCardButtonTooltip
      {...baseProps}
      onClick={preventOnClickOnMobileIos}
      title={(
        <Stack spacing={0.5}>
          <Stack direction='row' alignItems='center' spacing={1} mb={0.25}>
            {colorVariant === 'positive' && <CheckCircleIcon fontSize='medium' color='lightgreen' />}
            {colorVariant === 'negative' && <CancelIcon fontSize='medium' color='error' />}

            <PocketViewTomCardButtonTooltipTitle colorVariant={colorVariant}>
              {title}
            </PocketViewTomCardButtonTooltipTitle>
          </Stack>

          <Stack direction='row' alignItems='center' spacing={1}>
            <PocketViewTomCardButtonTooltipSubtitle>Передать:</PocketViewTomCardButtonTooltipSubtitle>
            <PocketViewTomCardButtonTooltipText>{nextStage?.title}</PocketViewTomCardButtonTooltipText>
          </Stack>

          <Stack direction='row' alignItems='center' spacing={1}>
            <PocketViewTomCardButtonTooltipSubtitle>Срок прохождения:</PocketViewTomCardButtonTooltipSubtitle>
            <PocketViewTomCardButtonTooltipText>{getWordWithEnding(nextStage?.duration || 0, 'день', 'дня', 'дней')}</PocketViewTomCardButtonTooltipText>
          </Stack>

          <Stack direction='row' alignItems='center' spacing={1}>
            <PocketViewTomCardButtonTooltipSubtitle>Статус тома:</PocketViewTomCardButtonTooltipSubtitle>
            <PocketViewTomCardButtonTooltipText>{tomStatus}</PocketViewTomCardButtonTooltipText>
          </Stack>
        </Stack>
      )}
      componentsProps={{
        tooltip: {
          style: tooltipStyle
        },
      }}
    >
      {children}
    </PocketViewTomCardButtonTooltip>
  )
}
