export type TTransferType = "agreed" | "agreedWithRemarks" | "notAgreed";

export const agreementTomCardButtonInfoTitle: Record<TTransferType, string> = {
  agreed:
    "Том перейдет на следующий этап по позитивному сценарию схемы согласования.",
  agreedWithRemarks:
    "Том перейдет на следующий этап по позитивному сценарию. Замечания в статусе Создано, в т. ч. с признаком Повторное без направления, оставленные пользователями компании на текущем этапе согласования, будут автоматически направлены на устранение компании, ответственной за этап при негативном результате прохождения этапа.",
  notAgreed:
    "Том перейдет на следующий этап по негативному сценарию схемы согласования с автоматическим направлением замечаний в статусе Создано, в т. ч. с признаком Повторное без направления.",
};

export const agreementTomCardButtonSnackbarText: Record<TTransferType, string> =
  {
    agreed: "Том передан на следующий этап согласования.",
    agreedWithRemarks:
      "Том передан на следующий этап согласования. Реестр замечаний направлен на устранение.",
    notAgreed: "Том не согласован. Реестр замечаний направлен на устранение.",
  };
