import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

export const CloudCogIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M12 19C12 19.34 12.03 19.67 12.08 20H6.5C5 20 3.69 19.5 2.61 18.43C1.54 17.38 1 16.09 1 14.58C1 13.28 1.39 12.12 2.17 11.1C2.95 10.08 4 9.43 5.25 9.15C5.67 7.62 6.5 6.38 7.75 5.43C9 4.48 10.42 4 12 4C13.95 4 15.6 4.68 16.96 6.04C18.32 7.4 19 9.05 19 11C20.15 11.13 21.1 11.63 21.86 12.5C21.92 12.55 21.96 12.63 22 12.69C21.1 12.25 20.08 12 19 12C18.31 12 17.63 12.11 17 12.29V11C17 9.62 16.5 8.44 15.54 7.46C14.56 6.5 13.38 6 12 6C10.62 6 9.44 6.5 8.46 7.46C7.5 8.44 7 9.62 7 11H6.5C5.53 11 4.71 11.34 4.03 12.03C3.34 12.71 3 13.53 3 14.5C3 15.47 3.34 16.29 4.03 17C4.71 17.66 5.53 18 6.5 18H12.08C12.03 18.33 12 18.66 12 19ZM23.83 20.64L22.83 22.37C22.76 22.5 22.63 22.5 22.5 22.5L21.27 22C21 22.18 20.73 22.34 20.43 22.47L20.24 23.79C20.22 23.91 20.11 24 20 24H18C17.86 24 17.76 23.91 17.74 23.79L17.55 22.47C17.24 22.35 16.96 22.18 16.7 22L15.46 22.5C15.34 22.5 15.21 22.5 15.15 22.37L14.15 20.64C14.1221 20.5878 14.1128 20.5276 14.1237 20.4694C14.1346 20.4113 14.1651 20.3585 14.21 20.32L15.27 19.5C15.2301 19.1679 15.2301 18.8321 15.27 18.5L14.21 17.68C14.1631 17.6429 14.1312 17.59 14.1201 17.5312C14.1091 17.4724 14.1197 17.4116 14.15 17.36L15.15 15.63C15.22 15.5 15.35 15.5 15.46 15.5L16.7 16C16.96 15.82 17.25 15.66 17.55 15.53L17.74 14.21C17.7517 14.15 17.7842 14.0961 17.8318 14.0577C17.8793 14.0193 17.9389 13.9989 18 14H20C20.11 14 20.22 14.09 20.23 14.21L20.42 15.53C20.73 15.65 21 15.82 21.27 16L22.5 15.5C22.63 15.5 22.76 15.5 22.82 15.63L23.82 17.36C23.88 17.47 23.85 17.6 23.76 17.68L22.7 18.5C22.73 18.67 22.74 18.83 22.74 19C22.74 19.17 22.72 19.33 22.7 19.5L23.77 20.32C23.86 20.4 23.89 20.53 23.83 20.64ZM20.5 19C20.5 18.17 19.82 17.5 19 17.5C18.18 17.5 17.5 18.17 17.5 19C17.5 19.83 18.16 20.5 19 20.5C19.84 20.5 20.5 19.83 20.5 19Z' />
    </SvgIcon>
  )
}
