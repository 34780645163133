import { Tooltip, Typography } from "@mui/material";
import { tooltipStyle } from "pages/PocketView/components/PocketViewTomCardTooltip/PocketViewTomCardTooltip.types";
import { FC } from "react";
import { theme } from "styles/theme";
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { IAgreementTomCardButtonInfoProps } from "./AgreementTomCardButtonInfo.types";

export const AgreementTomCardButtonInfo: FC<IAgreementTomCardButtonInfoProps> = ({ tooltipTitle }) => {
  return (
    <Tooltip
      title={<Typography variant="body2" color={theme.palette.secondary.dark}>{tooltipTitle}</Typography>}
      onMouseEnter={e => e.stopPropagation()}
      componentsProps={{
        tooltip: {
          style: tooltipStyle
        },
      }}>
      <InfoIcon fontSize='inherit' sx={{ opacity: 0.5 }} />
    </Tooltip>
  )
}