import styled from 'styled-components'
import { Stack, Tabs, Typography } from '@mui/material'

interface IMainCardWrapperProps {
  mainCardFullView?: boolean
}

export const MainCardWrapper = styled(Stack)<IMainCardWrapperProps>`
  /* grid-area: main; */
  grid-column: ${props => props.mainCardFullView ? '1 / 3' : '1 / 2'};
  grid-row: span 2;
  gap: 12px;
  padding: 20px;
  border-radius: 20px;
  background-color: ${props => props.theme.palette.bg.white};
`

export const MainCardTabs = styled(Tabs)`
  min-height: 40px;
  min-width: 130px;

  & .MuiTab-root {
    min-width: fit-content;
    min-height: 40px;
    padding: 10px 8px;
    font-weight: 400;
    text-transform: none;
  }
`