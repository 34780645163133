import { Menu } from '@mui/material'
import styled from 'styled-components'

export const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    border-radius: 16px;
    transform: translateY(8px) !important;
    padding: 8px;
  }

  & .MuiList-root {
    padding: 0;
  }
`
