import { Box, Stack } from '@mui/material'
import React, { FC } from 'react'
import { MoreTabProps } from './MoreTab.types'
import { MapHeight } from '../AboutTab'
import { RowItem } from '../RowItem'
import { formatMaskPhone } from '../../../../../../components/PhoneFieldForm/utils'
import { ScrollableContainer } from '../../../../../../styles/global/ScrollableContainer'
import EmptyPreview from '../../../../../../assets/empty-project-preview.png'
import { StyledCarousel } from './MoreTab.styles'

export const MoreTab: FC<MoreTabProps> = ({ responsiblePersons, images, mainCardFullView }) => {
  const { client, architector, contractors } = responsiblePersons

  const clientName =
    client?.lastName || client?.firstName || client?.middleName
      ? `${client?.lastName || ''} ${client?.firstName || ''} ${client?.middleName || ''}`
      : client?.customName || ''

  const architectorName =
    architector?.lastName || architector?.firstName || architector?.middleName
      ? `${architector?.lastName || ''} ${architector?.firstName || ''} ${architector?.middleName || ''}`
      : architector?.customName || ''

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='start' spacing={2.5}>
      {mainCardFullView &&
        (images?.length > 1 ? (
          <StyledCarousel
            animation='slide'
            autoPlay={false}
            indicators={false}
            navButtonsAlwaysVisible={true}
            navButtonsWrapperProps={{ className: 'navButtonsWrapper' }}
          >
            {images.map((image) => (
              <Box width={277}>
                <img
                  src={image.imagePreview}
                  width={277}
                  height={MapHeight}
                  alt='Превью проекта'
                  style={{ borderRadius: 16 }}
                />
              </Box>
            ))}
          </StyledCarousel>
        ) : (
          <Box width={277}>
            <img
              src={images[0]?.imagePreview || EmptyPreview}
              width={277}
              height={MapHeight}
              alt='Превью проекта'
              style={{ borderRadius: 16 }}
            />
          </Box>
        ))}

      <ScrollableContainer alignItems='start' flex={1} spacing={1.25}>
        <RowItem
          title='Заказчик'
          rowStrings={[client?.company || '', clientName, client?.phone ? formatMaskPhone(client.phone) : '']}
          emptyDataText='Заказчик проекта не указан'
        />

        <RowItem
          title='Проектировщик'
          rowStrings={[
            architector?.company || '',
            architectorName,
            architector?.phone ? formatMaskPhone(architector.phone) : '',
          ]}
          emptyDataText='Проектировщик проекта не указан'
        />

        {contractors?.length ? (
          contractors.map((contractor) => {
            const contractorName =
              contractor?.lastName || contractor?.firstName || contractor?.middleName
                ? `${contractor?.lastName || ''} ${contractor?.firstName || ''} ${contractor?.middleName || ''}`
                : contractor?.customName || ''

            return (
              <RowItem
                title='Подрядчик'
                rowStrings={[
                  contractor?.company || '',
                  contractorName,
                  contractor?.phone ? formatMaskPhone(contractor.phone) : '',
                ]}
              />
            )
          })
        ) : (
          <RowItem title='Подрядчик' rowStrings={[]} emptyDataText='Подрядчик проекта не указан' />
        )}
      </ScrollableContainer>
    </Stack>
  )
}
