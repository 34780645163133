import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import Header from '../../components/Header'
import { MainContainer, InnerContainer } from '../HomeLayout/styles'
import { Menu, MenuItem, Typography } from '@mui/material'
import { useContext, useState, useCallback, useLayoutEffect, useEffect, useMemo } from 'react'
import { useGetProjectByIdQuery, useGetProjectsQuery, useGetWorksWidgetQuery } from '../../api/projects'
import { GetProjectsResponse } from '../../api/projects/api.types'
import Progress from '../../components/Progress'
import { uiContext } from '../../contexts/ui'
import useBreadcrumbs from '../../hooks/useBreadcrumbs'
import { SchedulesNavBar } from '../../pages/Schedules/components/SchedulesNavBar'

export const WorksLayout = () => {
  const cUI = useContext(uiContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { projectId: projectIdString, scheduleId: scheduleIdString } = useParams()
  const projectId = Number(projectIdString)
  const scheduleId = scheduleIdString ? Number(scheduleIdString) : undefined

  const {
    data: projectData,
    isLoading: projectLoading,
    isFetching: isProjectFetching,
    error,
  } = useGetProjectByIdQuery({ id: projectId })
  const { data: project } = projectData || {}
  const projectName = project?.shifrName || ''
  const loading = projectLoading || isProjectFetching
  const isSchedulesPage = location.pathname.endsWith('schedules')
  const { data: projectsData, isLoading: projectsLoading, isFetching: projectsFetching } = useGetProjectsQuery({})
  const { data: projects } = projectsData || ({} as GetProjectsResponse)

  const { data: widgetData } = useGetWorksWidgetQuery({ id: projectId }, { skip: !projectId })
  const schedulesData = widgetData?.data || []

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const MenuOpen = Boolean(anchorEl)

  const scheduleName = useMemo(() => {
    if (!isSchedulesPage && schedulesData.length > 0) {
      return schedulesData.find((item) => item.schedule.id === scheduleId)?.schedule.title
    }

    return undefined
  }, [isSchedulesPage, scheduleId, schedulesData])

  const ProjectBreadClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onMenuItemClick = useCallback(
    (projectId: number) => {
      navigate(`/project/${projectId}/schedules`)
      onClose()
    },
    [onClose],
  )

  useBreadcrumbs(
    isSchedulesPage
      ? [
          {
            title: projectName,
            onClick: (e) => ProjectBreadClick(e),
            MenuOpen,
            type: 'project',
          },
          { title: 'Графики проектирования' },
        ]
      : [
          {
            title: projectName,
            onClick: (e) => ProjectBreadClick(e),
            MenuOpen,
            type: 'project',
          },
          { title: 'Графики проектирования' },
          { title: scheduleName!, type: 'longname' },
        ],
    [MenuOpen, projectName, isSchedulesPage, schedulesData],
    loading,
  )

  useEffect(() => {
    if (error && 'originalStatus' in error) {
      error.originalStatus === 403 && navigate('/home')
    }
  }, [error])

  useLayoutEffect(() => {
    cUI.setShowSearch(false)
    return () => {
      cUI.setShowSearch(true)
    }
  }, [])

  return (
    <>
      {isSchedulesPage && schedulesData && schedulesData.length > 0 && <SchedulesNavBar project={project} />}
      <MainContainer>
        <Header />
        <InnerContainer>
          <Outlet />
        </InnerContainer>
      </MainContainer>
      <Menu
        anchorEl={anchorEl}
        open={MenuOpen}
        onClose={onClose}
        MenuListProps={{
          style: {
            width: '100%',
          },
        }}
        PaperProps={{
          style: {
            display: 'flex',
            justifyContent: 'center',
            minWidth: 200,
            maxWidth: 400,
            maxHeight: 300,
          },
        }}
      >
        {projectsLoading || projectsFetching ? (
          <Progress />
        ) : projects?.length > 1 ? (
          projects
            .filter((project) => project.id !== projectId!)
            ?.map((project) => (
              <MenuItem onClick={() => onMenuItemClick(project.id)} style={{ maxWidth: 450 }} key={project.id}>
                <Typography
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {project.shifrName}
                </Typography>
              </MenuItem>
            ))
        ) : (
          <MenuItem style={{ maxWidth: 450 }} disabled>
            <Typography>Другие проекты отсутствуют</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
