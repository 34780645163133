import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { GetModelRequest, GetModelResponse } from "./types"

const BASE_URL = 'https://cache.tangl.cloud/'



const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
})

export const tanglApi = createApi({
  reducerPath: 'tanglTestApi',
  baseQuery,
  endpoints: (build) => ({
    getModel: build.query<GetModelResponse, GetModelRequest>({
      query: ({ modelId, token }) => {
        return {
          url: `/api/app/metaModels/${modelId}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: 'GET',
        }
      },
    }),
    getCompanyModels: build.query({
      query: ({ companyId, token }) => {
        return {
          url: `/api/app/metaModelsList/${companyId}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
    }),
    getElementsTree: build.query({
      query: ({ versionId, token }) => {
        return {
          url: `/api/app/modelsCache/${versionId}/tree`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
    }),
  }),
  
})

export const { useGetModelQuery, useGetCompanyModelsQuery, useGetElementsTreeQuery, useLazyGetElementsTreeQuery } = tanglApi
