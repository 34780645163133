import { Stack } from '@mui/material'
import styled from 'styled-components'

export const SettingsWrapper = styled(Stack)`
  width: 100%;
  heith: 100%;
  background-color: ${(props) => props.theme.palette.bg.white};
  border-radius: 8px;
  margin-top: 10px;
  overflow: auto;
`

export const SettingsContent = styled(Stack)`
  flex-direction: row;
  gap: 120px;
`
