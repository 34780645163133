import { InfoOutlined as InfoIcon } from '@mui/icons-material'
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PersonSharpIcon from '@mui/icons-material/PersonSharp'
import SlowMotionVideoRoundedIcon from '@mui/icons-material/SlowMotionVideoRounded'
import VerifiedIcon from '@mui/icons-material/Verified'
import { Avatar, Collapse, Divider, Stack, Typography } from '@mui/material'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { tooltipStyle } from 'pages/PocketView/components/PocketViewTomCardTooltip/PocketViewTomCardTooltip.types'
import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { theme } from 'styles/theme'
import { getLocalTime } from 'utils/getLocalTime'

import { tomSelector } from '@store/slices/documentsPages/tom'
import { useTypedSelector } from '@store/store'

import {
  useGetAgreementStagesHistoryQuery,
  useTransferTomToNextStageFromHistoryMutation,
} from '../../../../api/agreementHistory'
import { HistoryAgreementTomItem } from '../../../../api/agreementHistory/types'
import Progress from '../../../../components/Progress'
import Tooltip from '../../../../components/Tooltip'
import { PocketViewTomCardAvatarWrapper } from '../../../PocketView/components/PocketViewTomCard/PocketViewTomCard.styles'
import { PocketViewTomCardTooltip } from '../../../PocketView/components/PocketViewTomCardTooltip/PocketViewTomCardTooltip'
import {
  AgreementAccordion,
  AgreementAccordionDetails,
  AgreementAccordionDetailsItem,
  AgreementAccordionDetailsItemLine,
  AgreementAccordionSubtitle,
  AgreementAccordionSummary,
  AgreementAccordionText,
  AgreementAccordionTitle,
  AgreementTomCardButton,
  AgreementTomCardDoneInfo,
  AwaitingStatusBox,
} from './DocViewNavBarContentAgreementAccordion.styles'
import {
  TTransferType,
  agreementTomCardButtonInfoTitle,
  agreementTomCardButtonSnackbarText,
} from './DocViewNavBarContentAgreementAccordion.text'
import { DocViewNavBarContentAgreementAccordionProps } from './DocViewNavBarContentAgreementAccordion.types'
import { AgreementTomCardButtonInfo } from './components/AgreementTomCardButtonInfo'

export const DocViewNavBarContentAgreementAccordion: FC<DocViewNavBarContentAgreementAccordionProps> = ({
  item,
  initialPocketId,
}) => {
  const { projectId: projectIdStr, tomId: tomIdStr } = useParams<{ projectId: string; tomId: string }>()
  const projectId = parseInt(projectIdStr!)
  const tomId = parseInt(tomIdStr!)
  const { tom: currentTom } = useTypedSelector(tomSelector)
  const isAnnulled = currentTom?.features.annulment.annulled

  const { pocket, roadmap, stage, tom } = item
  const isPocketFinished = tom.status === 'ПРОЙДЕНО'
  const isAwaiting = tom.status === 'ОЖИДАЕТ ПОДТВЕРЖДЕНИЯ'
  const isInitialOpened = initialPocketId === pocket.id

  const [opened, setOpened] = useState<boolean>(isInitialOpened)
  const [appliedTransferType, setAppliedTransferType] = useState<TTransferType | null>(null)

  const [transferTomToNextStageFromHistory, transferTomToNextStageFromHistoryResponse] =
    useTransferTomToNextStageFromHistoryMutation()
  const { enqueueSnackbar } = useSnackbar()

  const onAccordionChange = (event: SyntheticEvent, isExpanded: boolean) => {
    setOpened(isExpanded)
  }

  const { data: stagesHistoryData, isFetching } = useGetAgreementStagesHistoryQuery(
    {
      projectId,
      tomId,
      pocketId: pocket.id,
    },
    { skip: !opened },
  )

  const { history = [], transfer, receiver } = stagesHistoryData || {}

  const agreedButtonShown = Boolean(transfer?.results[0]?.nextStage?.id || transfer?.results[0]?.isFinal)
  const notAgreedButtonShown = Boolean(transfer?.results[1]?.nextStage?.id)

  const getHistoryItemIcon = (item: HistoryAgreementTomItem, isStart: boolean = false) => {
    const { isFinal, resultType, withRemarks } = item

    if (isStart) return <SlowMotionVideoRoundedIcon fontSize='small' color={resultType ? 'lightgreen' : 'error'} />
    if (isFinal) return <DoneAllRoundedIcon fontSize='small' color='lightgreen' />
    if (resultType) return <CheckCircleRoundedIcon fontSize='small' color='lightgreen' />
    if (!resultType) return <CancelRoundedIcon fontSize='small' color='error' />

    return null
  }

  const onTransferClick = (resultId: number, transferType: TTransferType) => {
    transferTomToNextStageFromHistory({
      projectId,
      tomId,
      pocketId: pocket.id,
      resultId,
      withRemarks: transferType === 'agreedWithRemarks',
      localDateTime: getLocalTime(),
    })

    setAppliedTransferType(transferType)
    enqueueSnackbar(agreementTomCardButtonSnackbarText[transferType!], { variant: 'success' })
  }

  useEffect(() => {
    setAppliedTransferType(null)
  }, [item])

  // useMutationHandlers(
  //   transferTomToNextStageFromHistoryResponse,
  //   () => {
  //     enqueueSnackbar(agreementTomCardButtonSnackbarText[appliedTransferType!], { variant: 'success' })
  //   }
  // )

  const getTransferType = (item: HistoryAgreementTomItem): string => {
    let type: string | null = 'Не согласован'

    if (item.resultType) {
      type = item.withRemarks ? 'Согласован с замечаниями' : 'Согласован'
    }

    return type
  }

  return (
    <Stack spacing={3}>
      <AgreementAccordion expanded={opened} onChange={onAccordionChange} elevation={0}>
        <AgreementAccordionSummary>
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
            <Stack direction='row' alignItems='center' spacing={1} maxWidth='240px'>
              {roadmap.isMain && (
                <Tooltip title='Главная схема согласования'>
                  <VerifiedIcon fontSize='medium' color='primary' />
                </Tooltip>
              )}
              <AgreementAccordionTitle>{roadmap.title}</AgreementAccordionTitle>
            </Stack>

            <Stack className='MuiAccordionSummary-expandIconWrapper'>
              {opened ? <ExpandLessIcon fontSize='medium' /> : <ExpandMoreIcon fontSize='medium' />}
            </Stack>
          </Stack>

          <Collapse in={!opened}>
            <Stack spacing={0.25}>
              <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
                <AgreementAccordionSubtitle>Накладная:</AgreementAccordionSubtitle>
                <AgreementAccordionText>{pocket.title}</AgreementAccordionText>
              </Stack>
              <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
                <AgreementAccordionSubtitle>
                  {isAwaiting ? 'Статус' : isPocketFinished ? 'Результат' : 'Текущий этап'}:
                </AgreementAccordionSubtitle>
                <AgreementAccordionText
                  isAwaiting={isAwaiting}
                  customColor={isPocketFinished ? 'lightgreen' : undefined}
                >
                  {isPocketFinished ? 'Процедура пройдена' : isAwaiting ? 'Ожидает подтверждения' : stage?.title || '-'}
                </AgreementAccordionText>
              </Stack>
              <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
                <AgreementAccordionSubtitle>
                  {isAwaiting ? 'Дата направления' : isPocketFinished ? 'Дата согласования' : 'Срок прохождения этапа'}:
                </AgreementAccordionSubtitle>
                <AgreementAccordionText customBgColor={tom.isExpired ? 'error' : undefined}>
                  {tom.transferDate}
                </AgreementAccordionText>
              </Stack>
            </Stack>
          </Collapse>
        </AgreementAccordionSummary>

        <AgreementAccordionDetails>
          {isFetching ? (
            <Stack height={100}>
              <Progress />
            </Stack>
          ) : (
            <>
              {transfer && (
                <AgreementAccordionDetailsItem direction='row' spacing={1.25}>
                  <Stack alignItems='center' spacing={0.25}>
                    <AccessTimeFilledOutlinedIcon fontSize='small' color='info' />

                    {!!history.length && (
                      // <AgreementAccordionDetailsItemLine bgColor={history[0].resultType ? 'lightgreen' : 'error'} />
                      <AgreementAccordionDetailsItemLine bgColor='info' />
                    )}
                  </Stack>

                  <Stack flex={1} pb={2}>
                    <AgreementAccordionText>{stage?.title || '-'}</AgreementAccordionText>
                    <AgreementAccordionSubtitle>Срок прохождения этапа: {tom.transferDate}</AgreementAccordionSubtitle>

                    <Stack direction='row' alignItems='center' spacing={1.5}>
                      <PocketViewTomCardAvatarWrapper>
                        {transfer.person?.avatar ? (
                          <img src={transfer.person.avatar} width={32} height={32} alt='Аватар' />
                        ) : (
                          <PersonSharpIcon fontSize='medium' />
                        )}
                      </PocketViewTomCardAvatarWrapper>

                      <Stack maxWidth={200} maxHeight={32} flex={1} justifyContent='space-around'>
                        <Tooltip title={transfer.person?.company}>
                          <AgreementAccordionSubtitle>{transfer.person?.company || '—'}</AgreementAccordionSubtitle>
                        </Tooltip>
                        <Tooltip title={transfer.person?.name}>
                          <AgreementAccordionSubtitle>{transfer.person?.name || '—'}</AgreementAccordionSubtitle>
                        </Tooltip>
                      </Stack>
                    </Stack>

                    {!isAnnulled && transfer.access && !appliedTransferType && (
                      <Stack spacing={1.5} mt={1.5}>
                        {agreedButtonShown && (
                          <PocketViewTomCardTooltip result={transfer.results[0]} colorVariant='positive'>
                            <AgreementTomCardButton
                              onClick={() => onTransferClick(transfer.results[0].id, 'agreed')}
                              colorVariant='positive'
                              endIcon={
                                <AgreementTomCardButtonInfo tooltipTitle={agreementTomCardButtonInfoTitle['agreed']} />
                              }
                            >
                              Согласован
                            </AgreementTomCardButton>
                          </PocketViewTomCardTooltip>
                        )}

                        {agreedButtonShown && notAgreedButtonShown && transfer.existRemarks && (
                          <PocketViewTomCardTooltip result={transfer.results[0]} colorVariant='positive'>
                            <AgreementTomCardButton
                              onClick={() => onTransferClick(transfer.results[0].id, 'agreedWithRemarks')}
                              colorVariant='positive'
                              endIcon={
                                <AgreementTomCardButtonInfo
                                  tooltipTitle={agreementTomCardButtonInfoTitle['agreedWithRemarks']}
                                />
                              }
                            >
                              Согласован с замечаниями
                            </AgreementTomCardButton>
                          </PocketViewTomCardTooltip>
                        )}

                        {notAgreedButtonShown && (
                          <PocketViewTomCardTooltip result={transfer.results[1]} colorVariant='negative'>
                            <AgreementTomCardButton
                              onClick={() => onTransferClick(transfer.results[1].id, 'notAgreed')}
                              colorVariant='negative'
                              endIcon={
                                <AgreementTomCardButtonInfo
                                  tooltipTitle={agreementTomCardButtonInfoTitle['notAgreed']}
                                />
                              }
                            >
                              Не согласован
                            </AgreementTomCardButton>
                          </PocketViewTomCardTooltip>
                        )}
                      </Stack>
                    )}

                    {appliedTransferType && (
                      <AgreementTomCardDoneInfo disabled>
                        {appliedTransferType === 'agreed' && <CheckCircleIcon fontSize='medium' color='lightgreen' />}
                        {appliedTransferType === 'notAgreed' && <CancelIcon fontSize='medium' color='error' />}

                        <Typography>Передан на следующий этап</Typography>
                      </AgreementTomCardDoneInfo>
                    )}
                  </Stack>
                </AgreementAccordionDetailsItem>
              )}
              {receiver && (
                <Stack spacing={1}>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
                    <AgreementAccordionSubtitle>Накладная:</AgreementAccordionSubtitle>
                    <AgreementAccordionText>{pocket.title}</AgreementAccordionText>
                  </Stack>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
                    <AgreementAccordionSubtitle>Дата направления:</AgreementAccordionSubtitle>
                    <AgreementAccordionText>{tom.transferDate}</AgreementAccordionText>
                  </Stack>
                  <Stack>
                    <AgreementAccordionSubtitle>Ответственный за подтверждение</AgreementAccordionSubtitle>
                    <Divider />
                    <Stack direction='row' alignItems='center' spacing={1} mt={1}>
                      <Avatar src={receiver?.avatar} sx={{ width: 32, height: 32 }} />
                      <Stack>
                        <Typography variant='body2'>{receiver?.name}</Typography>
                        <Typography variant='body2'>{receiver?.company}</Typography>
                      </Stack>
                    </Stack>
                    <AwaitingStatusBox>
                      <Typography>Ожидает подтверждения</Typography>
                    </AwaitingStatusBox>
                  </Stack>
                </Stack>
              )}

              {history.map((item, index) => (
                <AgreementAccordionDetailsItem
                  direction='row'
                  spacing={1.25}
                  key={`${item.stageName}-${item.resultName}-${index}`}
                >
                  <Stack alignItems='center' spacing={0.25}>
                    {getHistoryItemIcon(item, index === history.length - 1)}

                    {index !== history.length - 1 && (
                      <AgreementAccordionDetailsItemLine bgColor={history[index].resultType ? 'lightgreen' : 'error'} />
                    )}
                  </Stack>

                  <Stack flex={1} pb={2}>
                    <AgreementAccordionText>{item.stageName}</AgreementAccordionText>
                    <Stack direction='row' spacing={0.5} maxWidth={250}>
                      <AgreementAccordionSubtitle disableEllipsis>
                        {item.isFinal ? 'Дата согласования' : 'Этап пройден'}:
                      </AgreementAccordionSubtitle>
                      <AgreementAccordionSubtitle>{item.transferDate}</AgreementAccordionSubtitle>
                    </Stack>
                    <Stack direction='row' spacing={0.5} maxWidth={250}>
                      <AgreementAccordionSubtitle disableEllipsis>Статус тома:</AgreementAccordionSubtitle>
                      <AgreementAccordionSubtitle>{item.tomStatus}</AgreementAccordionSubtitle>
                    </Stack>
                    <Stack direction='row' spacing={0.5} maxWidth={250}>
                      <AgreementAccordionSubtitle disableEllipsis>Тип:</AgreementAccordionSubtitle>
                      <AgreementAccordionSubtitle>{getTransferType(item)}</AgreementAccordionSubtitle>
                    </Stack>
                    {!item.isFinal && (
                      <Stack direction='row' spacing={0.5} maxWidth={250}>
                        <AgreementAccordionSubtitle disableEllipsis>Результат:</AgreementAccordionSubtitle>
                        <AgreementAccordionSubtitle customColor={item.resultType === false ? 'error' : undefined}>
                          {item.resultName}
                        </AgreementAccordionSubtitle>
                      </Stack>
                    )}

                    <Stack direction='row' alignItems='center' spacing={1.5}>
                      <PocketViewTomCardAvatarWrapper>
                        {item.person?.avatar ? (
                          <img src={item.person.avatar} width={32} height={32} alt='Аватар' />
                        ) : (
                          <PersonSharpIcon fontSize='medium' />
                        )}
                      </PocketViewTomCardAvatarWrapper>

                      <Stack maxWidth={200} maxHeight={32} flex={1} justifyContent='space-around'>
                        <Tooltip title={item.person?.company}>
                          <AgreementAccordionSubtitle>{item.person?.company || '—'}</AgreementAccordionSubtitle>
                        </Tooltip>
                        <Tooltip title={item.person?.name}>
                          <AgreementAccordionSubtitle>{item.person?.name || '—'}</AgreementAccordionSubtitle>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </Stack>
                </AgreementAccordionDetailsItem>
              ))}
            </>
          )}
        </AgreementAccordionDetails>
      </AgreementAccordion>
    </Stack>
  )
}
